import { SelectOptions } from '@luce/ui-kit'
import {
  ClientStatusEnum,
  InvoicePaymentStatusEnum,
  InvoiceStatusEnum,
  VoucherStatusEnum,
  WorkerDepartmentEnum
} from '__generated__/globalTypes'
import { ApolloError } from 'apollo-boost'
import compareAsc from 'date-fns/compareAsc'
import compareDesc from 'date-fns/compareDesc'
import parse from 'date-fns/parse'
import set from 'date-fns/set'
import { CleanerListContract } from 'ts/types/Cleaner'
import { ClientAddress, ClientContacts } from 'ts/types/Client'

import { AllClients_allClients_clients_contacts } from '../__generated__/AllClients'
import { VisitsByClient_visitsByClient_worker } from '../__generated__/VisitsByClient'
import { development, production, staging } from '../config'
import { Address, Contact } from '../ts/types/Contact'
import { ShortDaysList } from '../ts/types/Days'
import { PackageRequestsTasks } from '../ts/types/PackageRequest'
import { formatDate, formatTime24h, parseDate } from './date'

type Env = {
  env: string
  sentryDsn: string
}

export type AddressesValueOption = {
  id: string
  address: string
}

export function getEnv(): Env {
  switch (import.meta.env.VITE_REACT_APP_ENV) {
    case development.env:
      return development
    case staging.env:
      return staging
    case production.env:
      return production
    default:
      return development
  }
}

export function isLocal(): boolean {
  return import.meta.env.VITE_REACT_APP_ENV === 'local'
}

export function isStaging(): boolean {
  return import.meta.env.VITE_REACT_APP_ENV === 'staging'
}

export function isProduction(): boolean {
  return import.meta.env.VITE_REACT_APP_ENV === 'production'
}

export const getErrorMessage = (err: unknown, fallback: string): string => {
  if (err instanceof ApolloError) {
    if (err.graphQLErrors && err.graphQLErrors.length > 0) {
      return err.graphQLErrors[0]!.message
    } else if (err.networkError) {
      return err.networkError.message
    }
  }

  if (err instanceof Error) {
    return err.message
  }

  return fallback
}

export const preventSilentSignin = (): void => {
  if (PasswordCredential || FederatedCredential) {
    navigator?.credentials?.preventSilentAccess()
  }
}

export const getAvatarLetter = (user: {
  firstName?: string
  lastName?: string
}): string =>
  [user.firstName, user.lastName]
    .map((name) => (name && name.length > 0 ? name[0] : ''))
    .join('')

export const getScrollBarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth
}

export const getCapitalCase = (word: string, spliter: string = '_'): string => {
  return word
    .toLocaleLowerCase()
    .split(spliter)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

export const removeSpaces = (word: string): string => {
  return word.replace(/\s+/g, '')
}

export const getClientAddresses = (
  addresses: ClientAddress[]
): SelectOptions => {
  return addresses.map((item) => {
    return {
      value: item.id,
      label: item.fullAddress
    }
  })
}

export const getClientContacts = (
  contacts: ClientContacts[] | undefined
): string[] => {
  if (contacts) {
    return contacts
      .map((contact) => {
        return contact.phoneNumber
      })
      .flat()
  }
  return []
}

export const getServiceTypeCode = (serviceType: string): string => {
  return serviceType
    .split('_')
    .map((s) => s[0])
    .join('')
}

const findPrimaryContact = (contacts: Contact[]): Contact | undefined => {
  return contacts.find(({ primary }) => primary)
}

const findPrimaryAddress = (addresses: Address[]): Address | undefined => {
  return addresses.find(({ primary }) => primary)
}

const findOtherContact = (contacts: Contact[]): Contact | undefined => {
  return contacts.find(({ primary }) => !primary)
}

const findOtherAddress = (addresses: Address[]): Address | undefined => {
  return addresses.find(({ primary }) => !primary)
}

export const clientAddresses = (addresses: Address[]) => {
  return {
    primary: findPrimaryAddress(addresses),
    other: findOtherAddress(addresses)
  }
}
export const clientContacts = (contacts: Contact[]) => {
  return {
    primary: findPrimaryContact(contacts),
    other: findOtherContact(contacts)
  }
}

export const getKeyByValue = (obj: any, value: string): string | undefined => {
  return Object.keys(obj).find((key) => obj[key] === value)
}

export const getVisitRangeUpcoming = (): {
  fromDateUpcoming: Date
  toDateUpcoming: Date
  customToDateUpcoming: Date
} => {
  const currentDate = new Date()
  const fromDateUpcoming = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    date: currentDate.getDate()
  })
  const toDateUpcoming = set(currentDate, {
    year: currentDate.getFullYear() + 1,
    month: 11,
    date: 31
  })
  const customToDateUpcoming = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 2,
    date: currentDate.getDate()
  })
  return {
    fromDateUpcoming,
    toDateUpcoming,
    customToDateUpcoming
  }
}
const { fromDateUpcoming, toDateUpcoming } = getVisitRangeUpcoming()

export const getVisitRangePast = (): {
  fromDatePast: Date
  customFromDatePast: Date
  toDatePast: Date
} => {
  const currentDate = new Date()
  const fromDatePast = set(currentDate, {
    year: currentDate.getFullYear() - 1,
    month: 0,
    date: 1
  })
  const customFromDatePast = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() - 2,
    date: currentDate.getDate()
  })
  const toDatePast = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    date: currentDate.getDate()
  })
  return {
    fromDatePast,
    toDatePast,
    customFromDatePast
  }
}
const { fromDatePast, toDatePast } = getVisitRangePast()

export const getVisitFromDate = (tabSelected: number) => {
  switch (tabSelected) {
    case 0:
      return formatDate(fromDateUpcoming)
    case 1:
      return formatDate(fromDatePast)
    default:
      return formatDate(fromDateUpcoming)
  }
}

export const getVisitToDate = (tabSelected: number) => {
  switch (tabSelected) {
    case 0:
      return formatDate(toDateUpcoming)
    case 1:
      return formatDate(toDatePast)
    default:
      return formatDate(toDateUpcoming)
  }
}

export const sortByDate = <T>(
  array: T[],
  key: string,
  order: 'asc' | 'desc' = 'asc'
): T[] => {
  const currentDate = new Date()
  if (order === 'desc') {
    return array.sort((a, b) =>
      compareDesc(
        parse(b[key], 'yyyy-MM-dd', currentDate),
        parse(a[key], 'yyyy-MM-dd', currentDate)
      )
    )
  }

  return array.sort((a, b) =>
    compareAsc(
      parse(b[key], 'yyyy-MM-dd', currentDate),
      parse(a[key], 'yyyy-MM-dd', currentDate)
    )
  )
}

export function dateSorter<T>(
  a: T,
  b: T,
  key: string,
  order: 'asc' | 'desc' = 'asc'
): number {
  if (order === 'asc') {
    return compareAsc(parseDate(a[key]), parseDate(b[key]))
  }
  return compareDesc(parseDate(a[key]), parseDate(b[key]))
}

export function sortByStartDate<T>(
  a: T,
  b: T,
  order: 'asc' | 'desc' = 'asc'
): number {
  return dateSorter(a, b, 'startDate', order)
}

export function removeFileExtension(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, '')
}

export function addTimestampToFileName(
  file: File,
  extension: string,
  type: string
): File {
  return new File(
    [file],
    `${removeFileExtension(file.name)}_${new Date().valueOf()}.${extension}`,
    { type }
  )
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        resolve(encoded)
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

export function getBlobFromBase64(
  fileUrl: string,
  type = 'data:application/octet-stream;base64'
): Blob {
  const fileContent = atob(fileUrl)
  const defaultType = type === 'data:application/octet-stream;base64'

  return new Blob([
    defaultType ? fileContent : binaryStringToByte(fileContent)
  ], {
    type: type
  })
}

export function binaryStringToByte(
  byteCharacters: string
): Uint8Array {
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}

export function contactName(
  contact?: AllClients_allClients_clients_contacts
): string {
  if (!contact) {
    return ''
  }

  const { firstName, lastName } = contact
  return `${firstName} ${lastName}`
}

export function workerName(
  worker?: Omit<
    VisitsByClient_visitsByClient_worker,
    'manager' | '__typename'
  > | null
): string {
  if (!worker) {
    return ''
  }

  const { firstName, lastName } = worker
  return `${firstName} ${lastName}`
}

export const getContractName = (
  workerContracts: CleanerListContract[] | undefined
): string => {
  if (workerContracts?.length) {
    return workerContracts.map((contract) => contract.contractName).join(', ')
  } else {
    return 'No Contract'
  }
}

type WorkerFirstLastName = {
  firstName: string
  lastName: string
}

export function formatWorkerName(worker: WorkerFirstLastName): string {
  const { firstName, lastName } = worker
  return `${firstName} ${lastName}`
}

export function formatWorkerFirstLastName(
  firstName: string,
  lastName: string
): string {
  return `${firstName} ${lastName}`
}

export function getPhoneNumber(phoneNumber?: string) {
  // we actually use this because there is a phoneNumber which is optional,
  // for optional the phoneNumber value will not be validated when the value is smaller than 3,
  // so here we assume any phoneNumber value smaller than three will not be processed to the body request backend
  if (phoneNumber && phoneNumber.length > 3) {
    const formatNumber = ('' + phoneNumber).replace(/[()\s]|[- ]/g, '')
    return formatNumber
  }
  return ''
}

export const getUnitNumber = (unitFloor?: string, apartmentNumber?: string) => {
  if (unitFloor === '' && apartmentNumber === '') return ''
  if (unitFloor === '') return `#NA-${apartmentNumber}`
  if (apartmentNumber === '') return `#${unitFloor}-NA`

  return `#${unitFloor}-${apartmentNumber}`
}

// Set 'any' to allow any data type address passing into this function
export const concatAddress = <
  T extends {
    fullAddress: string
    unitNumber: string | null
    postalCode: string
  }
>(
  address: T
): string => {
  if (!address) {
    return '-'
  }

  const { fullAddress, unitNumber, postalCode } = address

  if (unitNumber) {
    return `${fullAddress} ${unitNumber} S${postalCode}`
  }

  return `${fullAddress} S${postalCode}`
}

export function getStatusFilter(
  statusFilter: string,
  managerFilter?: string
): object | null {
  if ((!statusFilter && !managerFilter) || managerFilter === 'ALL_MANAGER') {
    return null;
  }

  const setCleanerFilter: { [key: string]: string } = {};

  if (statusFilter && statusFilter !== 'ALL') {
    setCleanerFilter['status'] = statusFilter;
  }
  if (managerFilter) {
    setCleanerFilter['managerId'] = managerFilter;
  }

  return setCleanerFilter;
}

export const setUnitNumber = (
  outputType: 'unitFloor' | 'apartmentNumber',
  unitNumber: string
): string | undefined => {
  if (!unitNumber) {
    return ''
  }

  if (!unitNumber.includes('#')) {
    if (outputType === 'unitFloor') {
      return unitNumber
    }
    if (outputType === 'apartmentNumber') {
      return 'NA'
    }
  } else if (unitNumber.includes('#')) {
    const splittedUnitNumber = unitNumber.split('#')[1]
    //split unit number and return array index 1 #222-122 -> [#, 222-122] -> 222-112
    if (splittedUnitNumber.includes('-')) {
      const [unitFloor, apartmentNumber] = splittedUnitNumber.split('-')
      // return [222, 122]
      if (outputType === 'unitFloor') {
        return unitFloor
      }
      if (outputType === 'apartmentNumber') {
        return apartmentNumber
      }
    } else {
      if (outputType === 'unitFloor') {
        return splittedUnitNumber
      }
      if (outputType === 'apartmentNumber') {
        return 'NA'
      }
    }
  }
}

export const getDefaultManagerFilter = (
  managerId: string | undefined,
  defaultId: string
): string => {
  if (managerId === 'ALL_MANAGER' || managerId === undefined) {
    return ''
  }

  if (managerId) {
    return managerId
  }

  return defaultId
}

export const getInvoiceStatusStyle = (status: string) => {
  switch (status) {
    case InvoicePaymentStatusEnum.PAID:
      return 'paid'
    case InvoicePaymentStatusEnum.UNPAID:
      return 'unpaid'
    case InvoicePaymentStatusEnum.UNDERPAID:
      return 'underpaid'
    case InvoicePaymentStatusEnum.STRIPE_PROCESSING:
      return 'stripeProcessing'
    default:
      return 'unpaid'
  }
}

export const getInvoiceConfirmationStatus = (status: string) => {
  switch (status) {
    case InvoiceStatusEnum.NEW:
      return 'Pending'
    case InvoiceStatusEnum.CONFIRMED:
      return 'Confirmed'
    case InvoiceStatusEnum.CANCELLED:
      return 'Canceled'
    default:
      return 'Pending'
  }
}

export const getWaSentStatus = (waStatus: boolean) => {
  if (waStatus) {
    return 'Sent'
  } else {
    return  'Waiting'
  }
}

export const getVoucherStatusStyle = (status: string) => {
  switch (status) {
    case VoucherStatusEnum.ACTIVE:
      return 'underpaid'
    case VoucherStatusEnum.REDEEMED:
      return 'paid'
    case VoucherStatusEnum.EXPIRED:
      return 'unpaid'
    default:
      return 'underpaid'
  }
}

export const getBtnTerminationLabel = (
  startDate: string | undefined,
  endDate: string | undefined
) => {
  const parseCurrentDate = new Date()
  const parseStartDate = parseDate(startDate ?? '')
  if (parseCurrentDate >= parseStartDate && endDate) {
    return 'Update Termination Date'
  }
  if (parseCurrentDate >= parseStartDate) {
    return 'Terminate'
  }
  return 'Update End Date'
}

export const offsetCountPagination = (
  page: number,
  rowsPerPage: number
): object => {
  return {
    offset: page > 0 ? page * rowsPerPage : 0,
    limit: rowsPerPage
  }
}

export const splitStringOnUppercase = (str: string) => {
  const findMatch = str.match(/[A-Z][a-z]+|[0-9]+/g)

  if (findMatch) {
    return findMatch.join(' ')
  } else {
    return str
  }
}

export const convertSnakeCaseToSentence = (str: string) => {
  return capitalizeAllFirstLetter(str.replaceAll('_', ' '))
}

export const capitalizeAllFirstLetter = (str: string) => {
  const arr = str.split(' ')

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }

  return arr.join(' ')
}

export const getTitleCase = (word: string): string => {
  return word
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export function getSchedulesToString(tasks: PackageRequestsTasks[]): string[] {
  return tasks.map((task): string => {
    const { day, startTime, endTime } = task
    const dayLabel = ShortDaysList[day]

    return `${dayLabel} (${formatTime24h(startTime)} - ${formatTime24h(
      endTime
    )})`
  })
}

export function formatDisplayPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) {
    return 'No phone number'
  }
  const listOfPhoneCode = [
    '+65',
    '+63',
    '+62',
    '+91',
    '+55',
    '+64',
    '+66',
    '+38',
    '+1',
    '+7'
  ]
  if (listOfPhoneCode.includes(phoneNumber.slice(0, 2))) {
    return phoneNumber
  } else if (phoneNumber.slice(0, 3) === '+65') {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
      3,
      7
    )}-${phoneNumber.slice(7, 11)}`
  } else {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
      3,
      7
    )} ${phoneNumber.slice(7, 11)} ${phoneNumber.slice(11)}`
  }
}

export const tranformDownloadUrlToBlob = async (
  downloadUrl: string,
  fileName: string
): Promise<File> => {
  const fileExtension = fileName.split('.')
  const lastIndexFileExtension = fileExtension.length - 1

  return await fetch(downloadUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File(
        [blob],
        downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1),
        {
          type:
            fileExtension[lastIndexFileExtension] === 'pdf'
              ? 'application/pdf'
              : `image/${fileExtension[lastIndexFileExtension]}`
        }
      )

      return file
    })
    .catch((error) => {
      console.log('Unhandle blob the document', error)
      throw error
    })
}

export function fieldChangesObjWithoutWorkerId(fieldChanges: any): any {
  delete fieldChanges.worker_id
  delete fieldChanges.original_worker_id

  return fieldChanges
}

export function getWorkerID(changesList: any): string[] | null {
  if ('worker_id' in changesList) {
    const workerId = changesList.worker_id
    return workerId
  } else {
    return null
  }
}

export const workerTagDepartmentMapping: {
  [key in WorkerDepartmentEnum]: string
} = {
  [WorkerDepartmentEnum.HOME_CLEANING]: 'HC',
  [WorkerDepartmentEnum.OFFICE_CLEANING]: 'OC',
  [WorkerDepartmentEnum.AIRCON]: 'AC',
  [WorkerDepartmentEnum.HOME_BEAUTY]: 'HB',
  [WorkerDepartmentEnum.HANDYMAN]: 'HM',
  [WorkerDepartmentEnum.CAR_WASH]: 'CW',
  [WorkerDepartmentEnum.MASSAGE]: 'MS',
  [WorkerDepartmentEnum.BABYSITTER]: 'BS',
  [WorkerDepartmentEnum.CARPET_UPHOLSTERY]: 'CU',
  [WorkerDepartmentEnum.PET_CARE]: 'PC',
  [WorkerDepartmentEnum.ELDER_CARE]: 'EC',
  [WorkerDepartmentEnum.DEEP_CLEANING]: 'DC'
}

export const getWorkerTagVariantStatus = (status: WorkerDepartmentEnum | null) => {
  switch (status) {
    case WorkerDepartmentEnum.HOME_CLEANING:
      return 'homeCleaning'
    case WorkerDepartmentEnum.OFFICE_CLEANING:
      return 'officeCleaning'
    case WorkerDepartmentEnum.AIRCON:
      return 'airconCleaning'
    case WorkerDepartmentEnum.HOME_BEAUTY:
      return 'homeBeauty'
    case WorkerDepartmentEnum.HANDYMAN:
      return 'handyman'
    case WorkerDepartmentEnum.CAR_WASH:
      return 'carWash'
    case WorkerDepartmentEnum.MASSAGE:
      return 'massage'
    case WorkerDepartmentEnum.BABYSITTER:
      return 'babySitter'
    case WorkerDepartmentEnum.PET_CARE:
      return 'petCare'
    case WorkerDepartmentEnum.ELDER_CARE:
      return 'elderCare'
    case WorkerDepartmentEnum.DEEP_CLEANING:
      return 'deepCleaning'
    default:
      return 'homeCleaning'
  }
}

export function getOriginalWorkerID(changesList: any): string[] | null {
  if ('original_worker_id' in changesList) {
    const originalWorkerId = changesList.original_worker_id
    return originalWorkerId
  } else {
    return null
  }
}

export const getClientStatusLabel = (status: ClientStatusEnum) => {
  switch (status) {
    case ClientStatusEnum.ACTIVE:
      return 'Active'
    case ClientStatusEnum.DELETED:
      return 'Deleted'
    case ClientStatusEnum.INACTIVE:
      return 'Inactive'
    case ClientStatusEnum.RESTRICTED:
      return 'Restricted'
  }
}

export const getTagVariantStatus = (status: ClientStatusEnum) => {
  switch (status) {
    case ClientStatusEnum.ACTIVE:
      return 'assigned'
    case ClientStatusEnum.INACTIVE:
      return 'unassigned'
    case ClientStatusEnum.RESTRICTED:
      return 'autocomplete'
    case ClientStatusEnum.DELETED:
      return 'casual'

    default:
      return 'assigned'
  }
}
