import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  LeaveTypeEnum,
  NationalityEnum,
  PackageDepartmentEnum
} from '__generated__/globalTypes'

import { RootState } from '../../redux/slices'
import { Leave } from './lib'

export type FormFilterState = {
  [PackageDepartmentEnum.AIRCON]: boolean
  [PackageDepartmentEnum.HOME_CLEANING]: boolean
  [PackageDepartmentEnum.OFFICE_CLEANING]: boolean
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: boolean
  [PackageDepartmentEnum.HOME_BEAUTY] : boolean
  [PackageDepartmentEnum.CAR_WASH] : boolean
  [PackageDepartmentEnum.HANDYMAN] : boolean
  [PackageDepartmentEnum.MASSAGE] : boolean
  [PackageDepartmentEnum.BABYSITTER] : boolean
  [LeaveTypeEnum.AL]: boolean
  [LeaveTypeEnum.SL]: boolean
  [LeaveTypeEnum.NPL]: boolean
  [LeaveTypeEnum.HL]: boolean
  [NationalityEnum.Bangladeshi]: boolean
  [NationalityEnum.Burmese]: boolean
  [NationalityEnum.Cambodian]: boolean
  [NationalityEnum.Chinese]: boolean
  [NationalityEnum.Indian]: boolean
  [NationalityEnum.Indonesian]: boolean
  [NationalityEnum.Malaysian]: boolean
  [NationalityEnum.Singaporean]: boolean
  [NationalityEnum.SriLankan]: boolean
  [NationalityEnum.Thai]: boolean
  [NationalityEnum.Vietnamese]: boolean
}

export const initialFilterState: FormFilterState = {
  AIRCON: true,
  HOME_CLEANING: true,
  OFFICE_CLEANING: true,
  CARPET_UPHOLSTERY: true,
  HOME_BEAUTY: true,
  CAR_WASH: true,
  HANDYMAN: true,
  BABYSITTER: true,
  MASSAGE: true,
  AL: true,
  SL: true,
  NPL: true,
  HL: true,
  Bangladeshi: true,
  Burmese: true,
  Cambodian: true,
  Chinese: true,
  Indian: true,
  Indonesian: true,
  Malaysian: true,
  Singaporean: true,
  SriLankan: true,
  Thai: true,
  Vietnamese: true
}

type State = {
  allLeaves: Leave[]
  displayedLeaves: Leave[]
  displayTabLeaves: Leave[]
  selectedTab: number
  filterState: FormFilterState
  leaveFormModal: null | string
  leaveDetailModal: null | string
  selectedLeave: Leave | null
  page: number
}

export const initialState: State = {
  allLeaves: [],
  displayedLeaves: [],
  displayTabLeaves: [],
  selectedTab: 0,
  filterState: initialFilterState,
  leaveFormModal: null,
  leaveDetailModal: null,
  selectedLeave: null,
  page: 0
}

const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {
    setLeaves(state, { payload: leavesData }: { payload: Leave[] }) {
      state.allLeaves = leavesData
    },
    setFilterState(
      state,
      { payload: filterState }: { payload: FormFilterState }
    ) {
      state.filterState = filterState
    },
    setSelectedLeaveTab(state, { payload: selectedTab }: { payload: number }) {
      state.selectedTab = selectedTab
    },
    setLeaveFormModal(state, { payload }: PayloadAction<null | string>) {
      state.leaveFormModal = payload
    },
    setLeaveDetailModal(state, { payload }: PayloadAction<null | string>) {
      state.leaveDetailModal = payload
    },
    setSelectedLeave(state, { payload }: PayloadAction<null | Leave>) {
      state.selectedLeave = payload
    },
    setPage(state, { payload: pageCounting }: { payload: number }) {
      state.page = pageCounting
    }
  }
})

export const selectedTabSelector = (state: RootState) => {
  return state.leaveReducer.selectedTab
}

export const displayedLeaveSelector = (state: RootState) =>
  state.leaveReducer.allLeaves

export const filterStateSelector = (state: RootState) => {
  return state.leaveReducer.filterState
}

export const leaveFormModalSelector = (state: RootState): null | string => {
  return state.leaveReducer.leaveFormModal
}

export const leaveDetailModalSelector = (state: RootState): null | string => {
  return state.leaveReducer.leaveDetailModal
}

export const selectedLeaveSelector = (state: RootState): null | Leave => {
  return state.leaveReducer.selectedLeave
}

export const pageSelector = (state: RootState): number =>
  state.leaveReducer.page

export const { name, actions, reducer } = leaveSlice
