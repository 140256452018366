import {
  PackageDepartmentEnum,
  PackageRecurrenceEnum,
  VisitLineItemInput
} from '__generated__/globalTypes'
import { PackageDetail } from 'ts/types/Package'

import { PackageData_packageLineItems } from './../../__generated__/PackageData'
import { VisitLineItemData } from './../../__generated__/VisitLineItemData'
import { LineItemType } from './lineItem.slice'

export type LineItem = {
  id: string
  packageCode: string
  description: string
  unitsLabel: string
  unitsNumber: number
  unitValue: number
  totalValue: number
  duration: number
  durationPerUnit: number
  packageDetail: PackageDetail
}

const billingUnitMapping = {
  HOURLY: 'hour',
  FIXED: 'unit',
  PER_SQFT: 'sqft'
}

export const CARPET_UPHOLSTERY_CODES = [
  'CUDEC6T9',
  'CUDEC9T15',
  'CUDECMM2',
  'CUDECMM5',
  'CUDECMM10',
  'CUDECMM25'
]
export const DISCOUNT_UPHOLSTERY_CODE = 'CUDAF01'
export const ADJUSTMENT_CODE_UPHOLSTERY = 'CUAAF01'

export const getUnitLabel = (
  billingUnit: string,
  units: number,
  packageCode?: string
): string => {
  const label = billingUnitMapping[billingUnit]

  if (!label) {
    return 'Total'
  }

  if (packageCode && CARPET_UPHOLSTERY_CODES.includes(packageCode) && !units) {
    return 'Measurement (sqft)'
  }

  if (!units) {
    return `Total ${label}`
  }

  if (
    packageCode &&
    CARPET_UPHOLSTERY_CODES.includes(packageCode) &&
    units > 1
  ) {
    return `Total ${units} measure (sqft)`
  }

  if (units > 1) {
    return `Total ${units} ${label}s`
  }

  return `Total ${units} ${label}`
}

export function transformPackageLineItems(
  lineItems: PackageData_packageLineItems[]
): LineItem[] {
  return lineItems.map((item) => {
    const unitsNumber = item.units

    return {
      id: item.id,
      packageCode: item.packageDetail.code,
      description: item.packageDescription,
      unitsNumber,
      unitsLabel: getUnitLabel(
        item.packageDetail.billingUnit,
        unitsNumber,
        item.packageDetail.code
      ),
      durationPerUnit: item.packageDetail.durationPerUnit,
      duration: item.packageDetail.duration,
      unitValue: item.unitValue,
      totalValue: item.billingValue,
      packageDetail: item.packageDetail
    }
  })
}

export function transformVisitLineItems(
  lineItems: VisitLineItemData[]
): LineItem[] {
  return lineItems.map((item) => {
    const unitsNumber = item.units

    return {
      id: item.id,
      packageCode: item.packageDetail.code,
      description: item.packageDescription,
      unitsNumber,
      unitsLabel: getUnitLabel(
        item.packageDetail.billingUnit,
        unitsNumber,
        item.packageDetail.code
      ),
      durationPerUnit: item.packageDetail.durationPerUnit,
      duration: item.packageDetail.duration,
      unitValue: item.unitValue,
      totalValue: item.billingValue,
      packageDetail: item.packageDetail
    }
  })
}

export function transformToVisitLineItemsInput(
  lineItems: LineItem[]
): VisitLineItemInput[] {
  const removeDiscountUpholstery = lineItems.filter(
    (item) => item.packageCode !== DISCOUNT_UPHOLSTERY_CODE
  )

  return removeDiscountUpholstery.map((item) => {
    return {
      id: item.id,
      packageDetailId: item.packageDetail.id,
      packageDescription: item.description,
      billingUnit: item.packageDetail.billingUnit,
      units: item.unitsNumber,
      unitValue: item.unitValue
    }
  })
}

export function isUnitFormDisable(
  mode: 'add' | 'edit' | 'detail',
  variant: LineItemType,
  packageDetail: PackageDetail | null
): {
  totalUnitDisabled: boolean
  unitValueDisabled: boolean
} {
  const isDetailMode = mode === 'detail'
  const isEditingVisit = variant === 'visit' /* Editing visit allows all */

  /* Package Detail may be Null */
  if(isDetailMode || !packageDetail || isEditingVisit) {
    return {
      totalUnitDisabled: isDetailMode,
      unitValueDisabled: isDetailMode
    }
  }

  const disabledUnitTotalDepartments = [
    PackageDepartmentEnum.HOME_CLEANING,
    PackageDepartmentEnum.OFFICE_CLEANING,
  ]

  const adjustableRepeatEvery = [
    PackageRecurrenceEnum.FIXED_DW_MONTH,
    PackageRecurrenceEnum.FIXED_DW_QUARTER,
    PackageRecurrenceEnum.FIXED_DW_HALF_YEAR,
  ]

  const isDepartmentUnitTotalDisabled = disabledUnitTotalDepartments.includes(packageDetail.department)
  const isPackageRepeatEveryAdjustable = adjustableRepeatEvery.includes(packageDetail.repeatEvery)

  const isTotalUnitDisabled = isDetailMode || (isDepartmentUnitTotalDisabled && !isPackageRepeatEveryAdjustable)

  return {
    totalUnitDisabled: isTotalUnitDisabled,
    unitValueDisabled: isDetailMode
  }
}

export function setUnitValueField(packageDetail: PackageDetail): number {
  const { code, units, minUnits } = packageDetail
  if (CARPET_UPHOLSTERY_CODES.includes(code)) {
    return minUnits
  } else {
    return units
  }
}

export const unitValuePrice = (
  lineItemValue: number,
  packageCode: string
): string => {
  if (packageCode === DISCOUNT_UPHOLSTERY_CODE) {
    return `-$${Math.abs(lineItemValue).toFixed(2)}`
  }

  return `$${lineItemValue.toFixed(2)}`
}

export function replaceNumbersUnitInDescription(
  description: string,
  replacementNumber: number
): string {
  const regex = /(\b\d+(\.\d+)?\s*)(Unit|Sqft)\b/g

  const resultString = description.replace(regex, (match, num, _, unit) => {
    if (unit === 'Unit') {
      return `${replacementNumber} Units`
    }

    if (unit === 'Sqft' && match !== '600 Sqft') {
      return `${replacementNumber} Sqft`
    }

    return match
  })

  return resultString
}

// TODO: transform for taskLineItem
