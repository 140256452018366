import {
  PackageDepartmentEnum,
  PackageDetailBillingUnitEnum,
  PackageDetailSessionEnum,
  PackageRecurrenceEnum,
  PackageServiceTypeEnum,
  PackageUnitTypeEnum,
  ServiceTypeEnum
} from '__generated__/globalTypes'
import { shortDayByIndex } from 'ts/types/Days'
import { formatDate, timeFormat } from 'utils/date'

import { ClientFindBookingSchedule_clientFindBookingSchedule } from './../../__generated__/ClientFindBookingSchedule'
import { PackageDetailFragment } from './../../__generated__/PackageDetailFragment'
import { TimeSlot } from './booking.slice'

export enum PackageFrequencyGroupEnum {
  AD_HOC = 'AD_HOC',
  FORTNIGHT = 'FORTNIGHT',
  WEEKLY = 'WEEKLY',
  BI_YEARLY = 'BI_YEARLY',
  TRI_YEARLY = 'TRI_YEARLY',
  QUARTERLY = 'QUARTERLY'
}

export type RecurrencePackage =
  | 'Ad Hoc'
  | 'Weekly'
  | 'Fortnightly'
  | 'Daily'
  | 'Monthly'
  | 'Yearly'
  | 'Quarterly'
  | 'Tri-Yearly'

export const PackageUnitTypeMapping: Record<PackageUnitTypeEnum, string> = {
  [PackageUnitTypeEnum.ALL]: 'All',
  [PackageUnitTypeEnum.WALL]: 'Wall-Mounted',
  [PackageUnitTypeEnum.CEILING]: 'Casette (Ceiling)',
  [PackageUnitTypeEnum.DUCTED]: 'Ducted',
  [PackageUnitTypeEnum.PORTABLE]: 'Portable',
  [PackageUnitTypeEnum.WINDOW]: 'Window',
  [PackageUnitTypeEnum.R22]: 'R22',
  [PackageUnitTypeEnum.R410A]: 'R401',
  [PackageUnitTypeEnum.MATTRESS]: 'Mattress',
  [PackageUnitTypeEnum.SOFA]: 'Sofa',
  [PackageUnitTypeEnum.RUG]: 'Rug',
  [PackageUnitTypeEnum.CARPET]: 'Carpet',
  [PackageUnitTypeEnum.HANDYMAN]: 'Handyman',
  [PackageUnitTypeEnum.ELECTRICIAN]: 'Electrician',
  [PackageUnitTypeEnum.INSTALLATION]: 'Installation',
  [PackageUnitTypeEnum.PLUMBING]: 'Plumbing',
  [PackageUnitTypeEnum.SEDAN]: 'Sedan',
  [PackageUnitTypeEnum.SUV]: 'SUV',
  [PackageUnitTypeEnum.MPV]: 'MPV',
  [PackageUnitTypeEnum.FOOT_MASSAGE]: 'Foot Massage',
  [PackageUnitTypeEnum.BODY_MASSAGE]: 'Body Massage',
  [PackageUnitTypeEnum.DOG]: 'Dog',
  [PackageUnitTypeEnum.CAT]: 'Cat'
}

export const getPackageServiceTypeLabel = (
  serviceType: PackageServiceTypeEnum | ServiceTypeEnum
): string => {
  switch (serviceType) {
    case PackageServiceTypeEnum.AD_HOC:
    case ServiceTypeEnum.AD_HOC:
      return 'AdHoc' // TODO: This is unnecessary
    case PackageServiceTypeEnum.AIRCON:
    case ServiceTypeEnum.AIRCON:
      return 'Aircon' // TODO: This is unnecessary
    case PackageServiceTypeEnum.CHEMICAL_OVERHAUL:
    case ServiceTypeEnum.CHEMICAL_OVERHAUL:
      return 'Chemical Overhaul'
    case PackageServiceTypeEnum.CHEMICAL_WASH:
    case ServiceTypeEnum.CHEMICAL_WASH:
      return 'Chemical Wash'
    case PackageServiceTypeEnum.DISMANTLING:
    case ServiceTypeEnum.DISMANTLING:
      return 'Dismantling'
    case PackageServiceTypeEnum.EQUIPMENT:
    case ServiceTypeEnum.EQUIPMENT:
      return 'Equipment'
    case PackageServiceTypeEnum.GAS_LEAK_TESTING:
    case ServiceTypeEnum.GAS_LEAK_TESTING:
      return 'Gas Leak Testing'
    case PackageServiceTypeEnum.GAS_TOP_UP:
    case ServiceTypeEnum.GAS_TOP_UP:
      return 'Gas Top Up'
    case PackageServiceTypeEnum.GENERAL_SERVICING:
    case ServiceTypeEnum.GENERAL_SERVICING:
      return 'General Servicing'
    case PackageServiceTypeEnum.HOME_CLEANING:
    case ServiceTypeEnum.HOME_CLEANING:
      return 'Home Cleaning'
    case PackageServiceTypeEnum.INSTALLATION:
    case ServiceTypeEnum.INSTALLATION:
      return 'Installation'
    case PackageServiceTypeEnum.JET_WASH:
    case ServiceTypeEnum.JET_WASH:
      return 'Jet Wash'
    case PackageServiceTypeEnum.OFFICE_CLEANING:
    case ServiceTypeEnum.OFFICE_CLEANING:
      return 'Office Cleaning'
    case PackageServiceTypeEnum.REPAIR_DIAGNOSTIC:
    case ServiceTypeEnum.REPAIR_DIAGNOSTIC:
      return 'Repair Diagnostic'
    case PackageServiceTypeEnum.REPAIR_FOLLOW_UP:
    case ServiceTypeEnum.REPAIR_FOLLOW_UP:
      return 'Repair Follow Up'
    case PackageServiceTypeEnum.CONDENSER_WASH:
    case ServiceTypeEnum.CONDENSER_WASH:
      return 'Condenser Wash'
    case ServiceTypeEnum.ADJUSTMENT:
      return 'Adjustment'
    case PackageServiceTypeEnum.WARRANTY:
    case ServiceTypeEnum.WARRANTY:
      return 'Warranty' // TODO: This only used internal app

    default:
      console.log(
        'unknown service type on getPackageServiceTypeLabel',
        serviceType
      )
      return 'Unknown Service Type'
  }
}

export const PackageFrequencyGroupLabel: Record<
  PackageFrequencyGroupEnum,
  RecurrencePackage
> = {
  [PackageFrequencyGroupEnum.WEEKLY]: 'Weekly',
  [PackageFrequencyGroupEnum.FORTNIGHT]: 'Fortnightly',
  [PackageFrequencyGroupEnum.AD_HOC]: 'Ad Hoc',
  [PackageFrequencyGroupEnum.QUARTERLY]: 'Quarterly',
  [PackageFrequencyGroupEnum.TRI_YEARLY]: 'Tri-Yearly'
}

export const PackageDetailSessionMapping: Record<
  PackageDetailSessionEnum,
  string
> = {
  [PackageDetailSessionEnum.ALL]: 'All',
  [PackageDetailSessionEnum.DAY]: 'Day',
  [PackageDetailSessionEnum.EVENING]: 'Evening'
}

export const PackageDetailBillingUnitMapping: Record<
  PackageDetailBillingUnitEnum,
  string
> = {
  [PackageDetailBillingUnitEnum.FIXED]: 'Units',
  [PackageDetailBillingUnitEnum.HOURLY]: 'Hours',
  [PackageDetailBillingUnitEnum.PER_SQFT]: 'Per Sqft'
}

export type LineItemDetail = {
  packageDetailId: string
  serviceTitle: string
  serviceLabel: string
  unitsLabel: string
  unitsNumber: number
  unitValue: number
  billingValue: number
}

const HCOCRepeatEvery = {
  0: '',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  '5.5': 'Five and Half',
  6: 'Six'
}

export function getLineItemClientDetail(
  packageDetail: PackageDetailFragment
): LineItemDetail {
  const cacheKey = `${packageDetail.id}-${packageDetail.unitValue}`

  if (getLineItemClientDetail.cache[cacheKey]) {
    return getLineItemClientDetail.cache[cacheKey]
  }

  let serviceTitle = getPackageServiceTypeLabel(packageDetail.serviceType)

  let serviceLabel = PackageUnitTypeMapping[packageDetail.unitType]

  if (
    [
      PackageDepartmentEnum.HOME_CLEANING,
      PackageDepartmentEnum.OFFICE_CLEANING
    ].includes(packageDetail.department)
  ) {
    serviceTitle = `${getFrequencyLabel(
      packageDetail.repeatEvery,
      packageDetail.repeatEveryTimes
    )} ${getPackageServiceTypeLabel(packageDetail.serviceType)} (${
      PackageDetailSessionMapping[packageDetail.session]
    })`

    serviceLabel = `${HCOCRepeatEvery[packageDetail.repeatEveryTimes]} ${
      packageDetail.duration
    }-Hour Session`
  }

  getLineItemClientDetail.cache[cacheKey] = {
    packageDetailId: packageDetail.id,
    serviceTitle,
    serviceLabel,
    unitsLabel: PackageDetailBillingUnitMapping[packageDetail.billingUnit],
    unitsNumber: packageDetail.units,
    unitValue: packageDetail.unitValue,
    billingValue: packageDetail.serviceBillingValue
  }

  return getLineItemClientDetail.cache[cacheKey]
}
getLineItemClientDetail.cache = {} as { [key: string]: LineItemDetail }

export function transformScheduleBookingToTimeSlot(
  scheduleVariants: ClientFindBookingSchedule_clientFindBookingSchedule[]
): TimeSlot[] {
  return scheduleVariants.reduce<TimeSlot[]>((allTimeSlot, scheduleVariant) => {
    scheduleVariant.morning &&
      scheduleVariant.morning.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        allTimeSlot.push({
          id: `${scheduleVariant.day}-morning-${i}`,
          slotId: item.id,
          date: formatDate(startDate),
          day: shortDayByIndex(scheduleVariant.day),
          partOfDay: 'Morning',
          startTime: formatDate(startDate, timeFormat),
          endTime: formatDate(endDate, timeFormat),
          averageTravelTime: item.averageTravelTime,
          workerId: item.worker.id,
          workerName: `${item.worker.firstName} ${item.worker.lastName}`,
          rateValue: item.rateValue ?? 0,
          sessionValue: item.sessionValue ?? 0
        })
      })

    scheduleVariant.afternoon &&
      scheduleVariant.afternoon.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        allTimeSlot.push({
          id: `${scheduleVariant.day}-afternoon-${i}`,
          slotId: item.id,
          date: formatDate(startDate),
          day: shortDayByIndex(scheduleVariant.day),
          partOfDay: 'Afternoon',
          startTime: formatDate(startDate, timeFormat),
          endTime: formatDate(endDate, timeFormat),
          averageTravelTime: item.averageTravelTime,
          workerId: item.worker.id,
          workerName: `${item.worker.firstName} ${item.worker.lastName}`,
          rateValue: item.rateValue ?? 0,
          sessionValue: item.sessionValue ?? 0
        })
      })

    scheduleVariant.evening &&
      scheduleVariant.evening.forEach((item, i) => {
        const startDate = new Date(item.startAt)
        const endDate = new Date(item.endAt)

        allTimeSlot.push({
          id: `${scheduleVariant.day}-evening-${i}`,
          slotId: item.id,
          date: formatDate(startDate),
          day: shortDayByIndex(scheduleVariant.day),
          partOfDay: 'Evening',
          startTime: formatDate(startDate, timeFormat),
          endTime: formatDate(endDate, timeFormat),
          averageTravelTime: item.averageTravelTime,
          workerId: item.worker.id,
          workerName: `${item.worker.firstName} ${item.worker.lastName}`,
          rateValue: item.rateValue ?? 0,
          sessionValue: item.sessionValue ?? 0
        })
      })

    return allTimeSlot
  }, [])
}

export function groupTimeSlotByDate(
  timeSlots: TimeSlot[]
): Record<string, TimeSlot[]> {
  return timeSlots.reduce<Record<string, TimeSlot[]>>((group, timeslot) => {
    if (group[timeslot.date]) {
      group[timeslot.date].push(timeslot)
    } else {
      group[timeslot.date] = [timeslot]
    }

    return group
  }, {})
}

export function getFrequencyLabel(
  repeatEvery: PackageRecurrenceEnum,
  repeatEveryTimes: number
): RecurrencePackage {
  switch (repeatEvery) {
    case PackageRecurrenceEnum.WEEK:
      return 'Weekly'
    case PackageRecurrenceEnum.FORTNIGHT:
      return 'Fortnightly'
    case PackageRecurrenceEnum.MONTH:
      return 'Monthly'
    case PackageRecurrenceEnum.YEAR:
      if (repeatEveryTimes === 3) {
        return 'Tri-Yearly'
      } else if (repeatEveryTimes === 4) {
        return 'Quarterly'
      } else {
        return 'Yearly'
      }
    default:
      return 'Ad Hoc'
  }
}

export function getRepeatEveryFrequency(
  frequency: PackageFrequencyGroupEnum
): [PackageRecurrenceEnum[] | undefined, number | undefined] {
  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
      return [[PackageRecurrenceEnum.AD_HOC], 0]
    case PackageFrequencyGroupEnum.WEEKLY:
      return [[PackageRecurrenceEnum.WEEK], 1]
    case PackageFrequencyGroupEnum.FORTNIGHT:
      return [[PackageRecurrenceEnum.FORTNIGHT], 1]
    case PackageFrequencyGroupEnum.BI_YEARLY:
      return [[PackageRecurrenceEnum.YEAR], 2]
    case PackageFrequencyGroupEnum.TRI_YEARLY:
      return [[PackageRecurrenceEnum.YEAR], 3]
    case PackageFrequencyGroupEnum.QUARTERLY:
      return [[PackageRecurrenceEnum.YEAR], 4]

    default:
      return [undefined, undefined]
  }
}

export const CS_NUMBER_HOME = '+65 3138 4827'
export const CS_NUMBER_AIRCON = '+65 3138 4868'

export const workerLabel: Record<PackageDepartmentEnum, string> = {
  [PackageDepartmentEnum.AIRCON]: 'technician',
  [PackageDepartmentEnum.BABYSITTER]: 'cleaner',
  [PackageDepartmentEnum.HOME_CLEANING]: 'cleaner',
  [PackageDepartmentEnum.OFFICE_CLEANING]: 'cleaner',
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: 'cleaner',
  [PackageDepartmentEnum.HOME_BEAUTY]: 'technician',
  [PackageDepartmentEnum.HANDYMAN]: 'technician',
  [PackageDepartmentEnum.CAR_WASH]: 'cleaner',
  [PackageDepartmentEnum.MASSAGE]: 'technician',
  [PackageDepartmentEnum.PET_CARE]: 'cleaner',
  [PackageDepartmentEnum.ELDER_CARE]: 'cleaner',
  [PackageDepartmentEnum.DEEP_CLEANING]: 'technician'
}
