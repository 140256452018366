import { SelectOptions } from '@luce/ui-kit'
import {
  NationalityEnum,
  SortEnum,
  WorkerTipSortByEnum
} from '__generated__/globalTypes'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { WorkerTipList } from 'ts/types/WorkerTips'
import { offsetCountPagination } from 'utils/helpers'

import WorkerTipScreen, { WorkerTipsValues } from './WorkerTip.screen'
import { useAllWorkerTipsQuery } from './gqlhooks'
import { useDebouncedQueryParams } from 'hooks/useDebounceQueryParams'

interface Props {}

const getWorkerTipsListValues = (
  workerTipListData: WorkerTipList[]
): WorkerTipsValues[] => {
  if (workerTipListData) {
    return workerTipListData.map((workerTip) => {
      return {
        id: workerTip.id,
        title: workerTip.title,
        message: workerTip.message,
        department: workerTip.department,
        nationality: workerTip.nationality
      }
    }) as WorkerTipsValues[]
  }

  return []
}
const nationalityOptions: SelectOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Bangladeshi',
    value: NationalityEnum.Bangladeshi
  },
  {
    label: 'Burmese',
    value: NationalityEnum.Burmese
  },
  {
    label: 'Cambodian',
    value: NationalityEnum.Cambodian
  },
  {
    label: 'Chinese',
    value: NationalityEnum.Chinese
  },
  {
    label: 'Indian',
    value: NationalityEnum.Indian
  },
  {
    label: 'Indonesian',
    value: NationalityEnum.Indonesian
  },
  {
    label: 'Malaysian',
    value: NationalityEnum.Malaysian
  },
  {
    label: 'Singaporean',
    value: NationalityEnum.Singaporean
  },
  {
    label: 'Singapore PR',
    value: NationalityEnum.Singapore_PR
  },
  {
    label: 'SriLankan',
    value: NationalityEnum.SriLankan
  },
  {
    label: 'Thai',
    value: NationalityEnum.Thai
  },
  {
    label: 'Vietnamese',
    value: NationalityEnum.Vietnamese
  }
]

const WorkerTipContainer: React.FC<Props> = () => {
  const [order, setOrder] = useState<SortEnum>(SortEnum.ASC)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [workerTipModal, setWorkerTipModal] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [workerTip, setWorkerTip] = useState<WorkerTipList | null>(null)
  const [search, setSearch] = useState<string>('')
  const [nationality, setNationality] = useState<NationalityEnum | string>('')
  const [orderBy, setOrderBy] = useState<WorkerTipSortByEnum>(
    WorkerTipSortByEnum.department
  )
  const { enqueueSnackbar } = useSnackbar()
  const [
    queryAllWorkerTips,
    { error, data, loading, refetch }
  ] = useAllWorkerTipsQuery()

  const nationalityFilter = (
    nationality: NationalityEnum | string
  ): Record<string, string> => {
    const filter: Record<string, string> = {}

    if (nationality !== '') {
      filter.nationality = nationality as NationalityEnum
    }

    return filter
  }

  const searchParams = useDebouncedQueryParams(search, 'search')
  

  const handleSearch = (value) => {
    setSearch(value)
  }

  useEffect(() => {
    queryAllWorkerTips({
      ...offsetCountPagination(page, rowsPerPage),
      sort: [order],
      sortBy: [orderBy],
      ...nationalityFilter(nationality),
      search: searchParams
    })
  }, [rowsPerPage, order, orderBy, page, nationality, searchParams])

  const handleRequestSort = (property: string) => {
    switch (order) {
      case SortEnum.ASC:
        setOrder(SortEnum.DESC)
        break
      case SortEnum.DESC:
        setOrder(SortEnum.ASC)
        break
    }

    setOrderBy(property as WorkerTipSortByEnum)
  }

  function onChangePage(page: number): void {
    setPage(page)
  }

  function onChangeRowsPerPage(rows: number): void {
    setRowsPerPage(rows)
  }

  if (error) {
    enqueueSnackbar('Worker fetching failed', { variant: 'error' })
  }
  const workerTipListValues = getWorkerTipsListValues(
    data?.workerTipsByFilters.workerTips ?? []
  )
  const handleShowModal = (): void => {
    setWorkerTip(null)
    setWorkerTipModal(true)
  }
  const onCloseModal = (): void => {
    setWorkerTipModal(false)
  }
  const handleOpenDetails = (data: WorkerTipsValues) => {
    setWorkerTip(data)
    setWorkerTipModal(true)
  }
  function onChangeNationality(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { value: status } = event.target
    setNationality(status as NationalityEnum)
  }

  return (
    <WorkerTipScreen
      handleOpenDetails={handleOpenDetails}
      workerTip={workerTip}
      nationalityOptions={nationalityOptions}
      nationality={nationality}
      search={search}
      handleSearch={handleSearch}
      onChangeNationality={onChangeNationality}
      workerTipsValues={workerTipListValues}
      workerTipModal={workerTipModal}
      handleShowModal={handleShowModal}
      onCloseModal={onCloseModal}
      orderBy={orderBy}
      loading={loading}
      refetchWorkerTip={refetch}
      order={order === SortEnum.DESC ? 'desc' : 'asc'}
      handleRequestSort={handleRequestSort}
      pagination={{
        count: data?.workerTipsByFilters.count ?? 0,
        rowsPerPage,
        onChange: onChangePage,
        onChangeRowsPerPage,
        page
      }}
    />
  )
}

export default WorkerTipContainer
