import { JobInput, JobStatusEnum } from '__generated__/globalTypes'
import { formatTime24h } from 'utils/date'

import { ShortDaysList } from '../../ts/types/Days'
import {
  JobListItem,
  JobPackage,
  JobTasks,
  TemporaryPackageJobs
} from '../../ts/types/Job'
import { workerName } from '../helpers'
import {
  WorkerContractById_workerContract,
  WorkerContractById_workerContract_jobs
} from './../../__generated__/WorkerContractById'

export function getSchedules(tasks: JobTasks[]): string[] {
  return tasks.map((task): string => {
    const { day, startTime, endTime } = task
    const dayLabel = ShortDaysList[day]
    return `${dayLabel} (${formatTime24h(startTime)} - ${formatTime24h(
      endTime
    )})`
  })
}

export function mapJobPackagetoTemporary(
  job: JobPackage
): TemporaryPackageJobs {
  return {
    identifier: job.id,
    workerId: job.worker?.id,
    worker: job.worker,
    workerContract: null,
    startDate: job.startDate,
    endDate: job.endDate,
    tasks: job.tasks
  }
}

export function mapTemporaryJobPackageToJobListItem(
  job: TemporaryPackageJobs
): JobListItem {
  const { startDate, endDate, tasks, worker } = job

  return {
    id: '',
    contract: '',
    cleanerName: workerName(worker),
    startDate: startDate as string,
    endDate: endDate as string,
    schedules: tasks,
    status: JobStatusEnum.UNASSIGNED
  }
}

export function mapJobPackageToJobListItem(job: JobPackage): JobListItem {
  const { id, startDate, endDate, tasks, worker, status, workerContract } = job

  return {
    id,
    contract:
      workerContract?.id && workerContract?.contractType
        ? `#${workerContract?.id} ${workerContract?.contractType}`
        : '-',
    cleanerName: workerName(worker),
    startDate: startDate as string,
    endDate: endDate as string,
    schedules: tasks,
    status,
    manager: worker?.manager?.name ?? '',
    workerId: worker?.id
  }
}

export function mapJobPackageFormJob(
  job: WorkerContractById_workerContract_jobs,
  workerContract: WorkerContractById_workerContract | null
): JobListItem {
  const { id, startDate, endDate, tasks, worker, status } = job

  const schedules = getSchedules(tasks)

  return {
    id,
    contract:
      workerContract?.id && workerContract?.contractType
        ? `#${workerContract?.id} ${workerContract?.contractType}`
        : '-',
    cleanerName: workerName(worker),
    startDate: startDate as string,
    endDate: endDate as string,
    schedules,
    status,
    manager: worker?.manager?.name ?? '',
    workerId: worker?.id
  }
}

export function mapTemporaryJobstoJobInput(
  job: TemporaryPackageJobs
): JobInput {
  return {
    workerId: job.worker?.id ?? '',
    workerContractId: job.workerContract?.id ?? '',
    startDate: job.startDate,
    endDate: job.endDate,
    tasks: job.tasks.map((task) => ({ id: task.id }))
  }
}
