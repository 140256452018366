import {
  BankNameEnum,
  LanguageSpokenEnum,
  NationalityEnum,
  SexEnum,
  WorkerDepartmentEnum,
  WorkerModeOfTransportEnum
} from '__generated__/globalTypes'

export const NationalityMapping: { [key in NationalityEnum]: string } = {
  [NationalityEnum.Bangladeshi]: 'Bangladeshi',
  [NationalityEnum.Burmese]: 'Burmese',
  [NationalityEnum.Cambodian]: 'Cambodian',
  [NationalityEnum.Chinese]: 'Chinese',
  [NationalityEnum.Indian]: 'Indian',
  [NationalityEnum.Indonesian]: 'Indonesian',
  [NationalityEnum.Malaysian]: 'Malaysian',
  [NationalityEnum.Singapore_PR]: 'Singapore PR',
  [NationalityEnum.Singaporean]: 'Singaporean',
  [NationalityEnum.SriLankan]: 'SriLankan',
  [NationalityEnum.Thai]: 'Thai',
  [NationalityEnum.Vietnamese]: 'Vietnamese'
}

export const GenderMapping: { [key in SexEnum]: string } = {
  [SexEnum.Male]: 'Male',
  [SexEnum.Female]: 'Female',
  [SexEnum.NotDecided]: 'Not Decided'
}

export const BankNameMapping: { [key in BankNameEnum]: string } = {
  [BankNameEnum.ANZ]: 'ANZ',
  [BankNameEnum.BNPParibas]: 'BNP Paribas',
  [BankNameEnum.BangkokBank]: 'Bangkok Bank',
  [BankNameEnum.BankofIndia]: 'Bank of India',
  [BankNameEnum.BankofSingapore]: 'Bank of Singapore',
  [BankNameEnum.CIMB]: 'CIMB',
  [BankNameEnum.Citibank]: 'Citibank',
  [BankNameEnum.DBSBank]: 'DBS Bank',
  [BankNameEnum.HSBC]: 'HSBC',
  [BankNameEnum.HongLeongFinance]: 'Hong Leong Finance',
  [BankNameEnum.ICICBank]: 'ICIC Bank',
  [BankNameEnum.IndianOverseasBank]: 'Indian Overseas Bank',
  [BankNameEnum.IslamicBankofAsia]: 'Islamic Bank of Asia',
  [BankNameEnum.JPMorgan]: 'JP Morgan',
  [BankNameEnum.Maybank]: 'Maybank',
  [BankNameEnum.OCBC]: 'OCBC',
  [BankNameEnum.RHBBank]: 'RHB Bank',
  [BankNameEnum.SBI]: 'SBI',
  [BankNameEnum.StandardChartered]: 'Standard Chartered',
  [BankNameEnum.UOB]: 'UOB',
  [BankNameEnum.ICBCBank]: 'ICBC Bank',
  [BankNameEnum.TrustBank]: 'Trust Bank'
}

export const LanguageSpokenMapping: { [key in LanguageSpokenEnum]: string } = {
  [LanguageSpokenEnum.BahasaIndonesia]: 'Bahasa Indonesia',
  [LanguageSpokenEnum.Bengali]: 'Bengali',
  [LanguageSpokenEnum.Burmese]: 'Burmese',
  [LanguageSpokenEnum.Chinese]: 'Chinese',
  [LanguageSpokenEnum.English]: 'English',
  [LanguageSpokenEnum.Hindi]: 'Hindi',
  [LanguageSpokenEnum.Malay]: 'Malay',
  [LanguageSpokenEnum.Punjabi]: 'Punjabi',
  [LanguageSpokenEnum.Sinhala]: 'Sinhala',
  [LanguageSpokenEnum.Tamil]: 'Tamil',
  [LanguageSpokenEnum.Telagu]: 'Telagu',
  [LanguageSpokenEnum.Vietnamese]: 'Vietnamese'
}

export const WorkerDepartmentMapping: {
  [key in WorkerDepartmentEnum]: string
} = {
  [WorkerDepartmentEnum.HOME_CLEANING]: 'Home Cleaning',
  [WorkerDepartmentEnum.OFFICE_CLEANING]: 'Office Cleaning',
  [WorkerDepartmentEnum.AIRCON]: 'Aircon',
  [WorkerDepartmentEnum.CARPET_UPHOLSTERY]: 'Carpet Upholstery',
  [WorkerDepartmentEnum.HOME_BEAUTY]: 'Home Beauty',
  [WorkerDepartmentEnum.BABYSITTER]: 'Babysitter',
  [WorkerDepartmentEnum.CAR_WASH]: 'Car Wash',
  [WorkerDepartmentEnum.HANDYMAN]: 'Handyman',
  [WorkerDepartmentEnum.MASSAGE]: 'Massage',
  [WorkerDepartmentEnum.PET_CARE]: 'Pet Care',
  [WorkerDepartmentEnum.DEEP_CLEANING]: 'Deep Cleaning'
}

export const WorkerModeOfTransports = [
  WorkerModeOfTransportEnum.CAR,
  WorkerModeOfTransportEnum.PUBLIC_TRANSPORT,
  WorkerModeOfTransportEnum.BIKE
]

export const WorkerModeOfTransportMapping: {
  [key in WorkerModeOfTransportEnum]: string
} = {
  [WorkerModeOfTransportEnum.CAR]: 'Car',
  [WorkerModeOfTransportEnum.PUBLIC_TRANSPORT]: 'Public Transport',
  [WorkerModeOfTransportEnum.BIKE]: 'Bike',
}

export const getLanguageSpokenValues = (
  languagesSpoken: LanguageSpokenEnum[]
): string[] => {
  if (languagesSpoken) {
    return languagesSpoken.map((language) => {
      return LanguageSpokenMapping[language]
    })
  }
  return []
}
