import Color from '@luce/ui-kit/components/base/Color'
import { settings as ThemeColor } from '@luce/ui-kit/themes/default'
import { AllWorkerScheduler_allWorkers_workers } from '__generated__/AllWorkerScheduler'
import {
  START_WORKING_TIME,
  END_WORKING_TIME
} from 'components/cleanerContract/CleanerContractValues'
import { getStartTime, LeaveTypeMapping } from 'components/leave/lib'
import { addDays, format, parse } from 'date-fns'
import isBefore from 'date-fns/isBefore'
import set from 'date-fns/set'
import { daysNumber } from 'ts/types/Days'
import {
  SchedulerWorker,
  WorkerContractScheduler,
  WorkerRestDay,
  WorkerSchedulerLeave,
  WorkerSchedulerVisit
} from 'ts/types/WorkerSchedule'
import {
  formatDate,
  getDateRangeBetween,
  getDateStringWithoutZone,
  getDurationTime,
  getWeekFromDateString,
  setDateTime,
  yupTimeStringToMinutes,
  getDay
} from 'utils/date'
import { concatAddress } from 'utils/helpers'
import * as Yup from 'yup'

import { FindScheduleVariants_findScheduleVariants } from '../../__generated__/FindScheduleVariants'
import {
  TimeRange,
  VisitStatusEnum,
  LeaveTypeEnum,
  WorkerContractWeekTypeEnum,
  ContractTypeEnum,
  WorkerDepartmentEnum,
  WorkerContractDayTypeEnum
} from '../../__generated__/globalTypes'
import { CleanerData, WorkerSchedulerTask } from '../../ts/types/Cleaner'
import { ViewType } from '../calendar/Calendar.screen'
import { CustomTime } from '../package/form/lib'
import { FreeSlotData } from './schedulerCleaners.slice'
import {
  CleanerLeavesGroup,
  CleanerVisitsGroup,
  LeaveCellData,
  OffdayCellData,
  VisitCellData,
  WorkerTaskCellData,
  WorkerTasksGroup,
  WorkerRestDaysGroup
} from './weeklyScheduler.slice'

export const CUSTOM_START_TIME = '00:00:00'
export const CUSTOM_END_TIME = '23:59:59'

function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function mapCleanerToFreeSlots(cleaner: CleanerData): FreeSlotData[] {
  const { id, combinations } = cleaner
  return (combinations || []).map((timeSlot) => {
    const {
      id: timeSlotId,
      startAt,
      endAt,
      startTravelTime,
      nextTravelTime
    } = timeSlot
    return {
      ownerId: [parseInt(id, 10)],
      startDate: startAt, // startAt in UTC
      endDate: endAt, // endAt in UTC
      timeSlotId,
      startTravelTime,
      nextTravelTime,
      text: 'Recommendation'
    }
  })
}

export function mapScheduleVariantsToCleaner(
  scheduleVariant: FindScheduleVariants_findScheduleVariants
): CleanerData {
  const { worker, averageTravelTime } = scheduleVariant
  const {
    id,
    firstName,
    lastName,
    workerRating,
    ratingsCount,
    avatarUrl,
    manager,
    address,
    contactNumber,
    workerContracts,
    servicePostalCode,
    nationality
  } = worker

  return {
    id,
    firstName,
    lastName,
    ratingsCount,
    workerRating,
    avatarUrl: avatarUrl || '',
    address,
    contactNumber,
    workerContracts,
    servicePostalCode,
    nationality,
    combinations: scheduleVariant.combination,
    scheduleVariantAverageTravelTime: averageTravelTime,
    scheduleVariantId: uuidv4(),
    manager
  }
}

export function mapCustomTimesToTimeRanges(
  customTimes: CustomTime[]
): TimeRange[] {
  return customTimes
    .map((customTime, index) => {
      if (!customTime.startTime) {
        return null
      }
      return {
        day: index,
        startTime: `${customTime.startTime!}:00`, // hh:mm:ss
        endTime: `${customTime.endTime!}:00` // hh:mm:ss
      }
    })
    .filter(Boolean) as TimeRange[]
}

export function getTimeRanges(customTimes: CustomTime[]): TimeRange[] {
  const timeRanges = mapCustomTimesToTimeRanges(customTimes)
  return timeRanges.length ? timeRanges : allTimeRanges()
}

export function getDailyTimeRanges(customTime: CustomTime): TimeRange[] {
  const startTime = customTime.startTime || CUSTOM_START_TIME
  const endTime = customTime.endTime || CUSTOM_END_TIME
  return allTimeRanges().map(({ day }) => {
    return {
      startTime,
      endTime,
      day
    }
  })
}

export function allTimeRanges(): TimeRange[] {
  return Array(7)
    .fill(0)
    .map((_, index) => {
      return {
        startTime: CUSTOM_START_TIME,
        endTime: CUSTOM_END_TIME,
        day: index
      }
    })
}

export const mapVisitsByWorker = (
  visits: WorkerSchedulerVisit[]
): VisitCellData[] => {
  if (!visits) {
    return []
  }
  return visits.map((visit) => {
    const {
      id: visitId,
      serviceDate,
      startTime,
      endTime,
      worker,
      status,
      clientName,
      formattedAddress
    } = visit

    const endDateTime = parse(
      `${serviceDate} ${endTime}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    )

    let adjustedEndDate = endDateTime

    if (endDateTime.getHours() < 6) {
      // Need add one day if the value passed midnight
      adjustedEndDate = addDays(endDateTime, 1)
    }
    const adjustedEndDateString = format(adjustedEndDate, 'yyyy-MM-dd HH:mm:ss')

    return {
      visitId,
      text: clientName ?? '',
      ownerId: [worker ? parseInt(worker.id) : 0],
      startDate: `${serviceDate}T${startTime}`,
      endDate: adjustedEndDateString,
      clientName: clientName ?? '',
      duration: `${getDurationTime(startTime, endTime)?.hours ?? '0'}h`,
      location: formattedAddress,
      visitStatus: status
    }
  })
}

export const mapLeavesByWorker = (
  leaves: WorkerSchedulerLeave[]
): LeaveCellData[] => {
  if (!leaves) {
    return []
  }

  const endTimeOfDay = set(new Date(), { hours: 22, minutes: 59, seconds: 0 })

  return leaves.reduce((allDaysLeave, leave) => {
    const {
      id: leaveId,
      startDate: startDateString,
      endDate: endDateString,
      leaveType,
      worker
    } = leave

    const startDate = new Date(startDateString)
    const endDate = new Date(endDateString)

    if (
      !isBefore(endDate, set(endDate, { hours: 23, minutes: 0, seconds: 0 }))
    ) {
      endDate.setHours(22)
      endDate.setMinutes(59)
      endDate.setSeconds(0)
    }

    const leaveDates = getDateRangeBetween(startDate, endDate)

    leaveDates.forEach((date, indexDate) => {
      let startDateTime: Date, endDateTime: Date
      if (indexDate === 0) {
        startDateTime = setDateTime(date, startDate)
        if (leaveDates.length > 1) {
          endDateTime = setDateTime(date, endTimeOfDay)
        } else {
          endDateTime = endDate
        }
      } else if (leaveDates.length - 1 === indexDate) {
        startDateTime = setDateTime(date, getStartTime())
        endDateTime = setDateTime(date, endDate)
      } else {
        startDateTime = setDateTime(date, getStartTime())
        endDateTime = setDateTime(date, endTimeOfDay)
      }

      allDaysLeave.push({
        leaveId: `${leaveId}-${indexDate}`,
        text: LeaveTypeMapping[leave.leaveType],
        ownerId: [worker ? parseInt(worker.id) : 0],
        startDate: getDateStringWithoutZone(startDateTime),
        endDate: getDateStringWithoutZone(endDateTime),
        leaveType
      })
    })
    return allDaysLeave
  }, [] as LeaveCellData[])
}

export const mapTasksByWorker = (
  tasks: WorkerSchedulerTask[]
): WorkerTaskCellData[] => {
  if (!tasks) {
    return []
  }

  return tasks.map((task) => {
    const {
      id: taskId,
      taskDate,
      startTime,
      endTime,
      worker,
      fullAddress,
      postalCode,
      unitNumber
    } = task

    return {
      taskId,
      text: '',
      ownerId: [worker ? parseInt(worker.id) : 0],
      startDate: `${taskDate}T${startTime}`,
      endDate: `${taskDate}T${endTime}`,
      duration: `${getDurationTime(startTime, endTime)?.hours ?? '0'}h`,
      location: concatAddress({
        fullAddress,
        postalCode,
        unitNumber
      })
    }
  })
}

export const getAppointmentBgColor = (
  status: VisitStatusEnum | LeaveTypeEnum,
  duration?: number
): string => {
  switch (status) {
    case LeaveTypeEnum.HL:
    case LeaveTypeEnum.SL:
    case LeaveTypeEnum.CL:
      return '#FFF5F5'
    case LeaveTypeEnum.AL:
    case LeaveTypeEnum.NPL:
      return '#EDF1F4'
    case VisitStatusEnum.COMPLETED:
      return ThemeColor.palette.grey[500]
    case VisitStatusEnum.SCHEDULED:
      if (duration && duration < 4) {
        return '#C3E5F9'
      }

      return Color.base.secondTint
    case VisitStatusEnum.EXPIRED:
      return '#FAF2E0'
    case VisitStatusEnum.CANCELLED_BY_CLIENT:
    case VisitStatusEnum.CANCELLED_BY_OPS:
    case VisitStatusEnum.CANCELLED_BY_WORKER:
    case VisitStatusEnum.MISSED_BY_WORKER:
      return '#FADFDF'

    default:
      console.log('Unhandled getAppointmentBgColor', status)
      return '#DFFAED'
  }
}

export const getAppointmentTextColor = (
  status: VisitStatusEnum | LeaveTypeEnum
): string => {
  switch (status) {
    case LeaveTypeEnum.HL:
    case LeaveTypeEnum.SL:
    case LeaveTypeEnum.CL:
      return '#FF4444'
    case LeaveTypeEnum.AL:
    case LeaveTypeEnum.NPL:
      return ThemeColor.palette.primary.main
    case VisitStatusEnum.COMPLETED:
    case VisitStatusEnum.SCHEDULED:
    case VisitStatusEnum.EXPIRED:
    case VisitStatusEnum.CANCELLED_BY_CLIENT:
    case VisitStatusEnum.CANCELLED_BY_OPS:
    case VisitStatusEnum.CANCELLED_BY_WORKER:
    case VisitStatusEnum.MISSED_BY_WORKER:
      return ThemeColor.palette.primary.main

    default:
      console.log('Unhandled getAppointmentTextColor', status)
      return ThemeColor.palette.primary.main
  }
}

export const getAppointmentDurationColor = (duration: number): string => {
  if (duration && duration < 4) {
    return '#0FCAE4'
  }

  return '#27AE60'
}

export const sortCleanerByNameAsc = (
  a: CleanerData,
  b: CleanerData
): number => {
  if (a.firstName < b.firstName) {
    return -1
  }

  if (a.firstName > b.firstName) {
    return 1
  }

  return 0
}

export const getSchedulerFormSchema = (calendarView: ViewType) => {
  if (calendarView === 'week') {
    return Yup.object().shape({
      department: Yup.string().required('Cleaning Type is a required field'),
      postalCode: Yup.string()
        .required('Postal Code is a required field')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits'),
      frequencyOfJob: Yup.string().when(['department'], {
        is: (department) => department === WorkerDepartmentEnum.HOME_CLEANING,
        then: () => Yup.string().required('Job Frequency is a required field'),
        otherwise: () => Yup.string().nullable()
      }),
      hours: Yup.number()
        .typeError('Please enter a valid number')
        .required('Hours is a required field')
        .min(0, 'Minimum hours is 0')
        .max(23, 'Maximum hours is 23'),
      minutes: Yup.number()
        .typeError('Please enter a valid number')
        .required('Minutes is a required field')
        .min(0, 'Minimum hours is 0')
        .max(59, 'Maximum minutes is 59'),
      startDate: Yup.date().required('Start date is a required field'),
      endDate: Yup.date()
        .typeError('Please enter a valid date')
        .test(
          'is-greater',
          'Must be greater than Start Date',
          function (endDate: Date | undefined): boolean {
            const { startDate } = this.parent
            if (endDate && startDate) {
              return endDate.valueOf() >= startDate.valueOf()
            }
            return true
          }
        )
        .test(
          'is-two-weeks',
          'Date range max is 14 days',
          function (endDate: Date | undefined): boolean {
            const { startDate } = this.parent
            if (endDate && startDate) {
              const addNext14Days = addDays(new Date(startDate), 14)
              return endDate.valueOf() <= addNext14Days.valueOf()
            }
            return true
          }
        )
        .nullable(),
      startTime: Yup.string().nullable(),
      endTime: Yup.string()
        .test(
          'is-greater',
          'Must be greater than Start Time',
          function (endTime: string | undefined): boolean {
            const { startTime } = this.parent
            if (startTime && endTime) {
              return (
                yupTimeStringToMinutes(endTime) >
                yupTimeStringToMinutes(startTime)
              )
            }
            return true
          }
        )
        .nullable(),
      customTime: Yup.array().of(
        Yup.object().shape({
          startTime: Yup.string().nullable(),
          endTime: Yup.string()
            .test(
              'is-greater',
              'Must be greater then Start Time',
              function (endTime: string | undefined): boolean {
                const { startTime } = this.parent
                if (startTime && endTime) {
                  return (
                    yupTimeStringToMinutes(endTime) >
                    yupTimeStringToMinutes(startTime)
                  )
                }
                return true
              }
            )
            .nullable()
        })
      ),
      workerSkillIds: Yup.array().when(['department'], {
        is: (department) => department !== WorkerDepartmentEnum.HOME_CLEANING,
        then: () =>
          Yup.array()
            .of(Yup.string())
            .min(1, 'Select worker skill or add line item'),
        otherwise: () => Yup.array()
      })

      // scheduleMatching: Yup.string().when('customTime', {
      //   is: (customTime) => customTime?.length,
      //   then: Yup.string().required('Schedule Matching is a required field')
      // })
    })
  } else if (calendarView === 'timelineDay') {
    return Yup.object().shape({
      department: Yup.string().required('Cleaning Type is a required field'),
      postalCode: Yup.string()
        .required('Postal Code is a required field')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits'),
      hours: Yup.number()
        .typeError('Please enter a valid number')
        .required('Hours is a required field')
        .min(0, 'Minimum hours is 0')
        .max(23, 'Maximum hours is 23'),
      minutes: Yup.number()
        .typeError('Please enter a valid number')
        .required('Minutes is a required field')
        .min(0, 'Minimum hours is 0')
        .max(59, 'Maximum minutes is 59'),
      startDate: Yup.string().required('Start date is a required field'),
      endDate: Yup.string().nullable(),
      startTime: Yup.string()
        .nullable()
        .required('Start time is  a required field'),
      endTime: Yup.string().nullable()
    })
  }
}

export const getCleanerListValues = (
  cleanerListData: SchedulerWorker[] | undefined
): CleanerData[] => {
  if (cleanerListData) {
    return cleanerListData.map((cleaner) => {
      return {
        id: cleaner.id,
        firstName: cleaner.firstName,
        address: cleaner.address,
        servicePostalCode: cleaner.servicePostalCode,
        contactNumber: cleaner.contactNumber,
        nationality: cleaner.nationality,
        workerContracts: cleaner.workerContracts,
        lastName: cleaner.lastName,
        contact: cleaner.contactNumber,
        avatarUrl: cleaner.avatarUrl!,
        manager: cleaner.manager,
        workerRating: cleaner.workerRating ?? 0,
        ratingsCount: cleaner.ratingsCount ?? 0,
        department: cleaner.department as WorkerDepartmentEnum
      }
    })
  }

  return []
}

export const getVisitsFromAllWorkers = (
  allWorkers: AllWorkerScheduler_allWorkers_workers[]
): WorkerSchedulerVisit[] => {
  return allWorkers.reduce<WorkerSchedulerVisit[]>((allVisits, workers) => {
    const workerVisits = (workers.visits || []) as WorkerSchedulerVisit[]

    const visits = [...allVisits, ...workerVisits]

    return visits
  }, [])
}

export const getLeavesFromAllWorkers = (
  allWorkers: AllWorkerScheduler_allWorkers_workers[]
): WorkerSchedulerLeave[] => {
  return allWorkers.reduce<WorkerSchedulerLeave[]>((allLeaves, workers) => {
    const workerLeaves = (workers.leaveApplications ||
      []) as WorkerSchedulerLeave[]

    const leaves = [...allLeaves, ...workerLeaves]

    return leaves
  }, [])
}

export const getWorkerTasksFromAllWorkers = (
  allWorkers: AllWorkerScheduler_allWorkers_workers[]
): WorkerSchedulerTask[] => {
  return allWorkers.reduce<WorkerSchedulerTask[]>((allTasks, workers) => {
    const workerTasks = (workers.workerTasks || []) as WorkerSchedulerTask[]

    const tasks = [...allTasks, ...workerTasks]

    return tasks
  }, [])
}

export const getWorkerRestDaysFromAllWorkers = <
  T extends {
    id: string
    workerContracts: WorkerContractScheduler[] | null
  }
>(
  allWorkers: T[]
): WorkerRestDaysGroup => {
  return allWorkers.reduce<WorkerRestDaysGroup>((workerOffDays, worker) => {
    const [contract] = worker.workerContracts!.filter(
      // REFACTOR: this filter possible move to api
      (contract) => contract.contractType === ContractTypeEnum.FULL_TIME
    )

    if (contract?.workerContractDays) {
      const restContractDays = contract.workerContractDays
        .filter(
          ({ dayType, weekType }) =>
            dayType === WorkerContractDayTypeEnum.NON_WORKING_OFF_DAY ||
            (dayType === WorkerContractDayTypeEnum.ENHANCED_WORKING_OFF_DAY &&
              weekType !== WorkerContractWeekTypeEnum.ALL)
        )
        .map<WorkerRestDay>(({ id, day, weekType, dayType }) => {
          let invertedWeekType = weekType

          if (weekType !== WorkerContractWeekTypeEnum.ALL) {
            invertedWeekType =
              weekType === WorkerContractWeekTypeEnum.ODD
                ? WorkerContractWeekTypeEnum.EVEN
                : WorkerContractWeekTypeEnum.ODD
          }

          return {
            id,
            workerId: worker.id,
            day,
            startTime: START_WORKING_TIME,
            endTime: END_WORKING_TIME,
            weekType: invertedWeekType,
            dayType
          }
        })

      // Fallback if NON_WORKING_OFF_DAY not exist on server
      if (contract.workerContractDays.length < 7) {
        const existingWorkingDays = contract.workerContractDays.map(
          ({ day }) => day
        )
        daysNumber
          .filter((day) => !existingWorkingDays.includes(day))
          .forEach((day) => {
            restContractDays.push({
              id: `${worker.id}-${day}`,
              workerId: worker.id,
              day,
              startTime: START_WORKING_TIME,
              endTime: END_WORKING_TIME,
              weekType: WorkerContractWeekTypeEnum.ALL,
              dayType: WorkerContractDayTypeEnum.NON_WORKING_OFF_DAY
            })
          })
      }
      workerOffDays[worker.id] = restContractDays
    } else {
      workerOffDays[worker.id] = []
    }

    return workerOffDays
  }, {})
}

export const getUniqueWorkerIds = (
  cleaner: CleanerData[],
  queriedCleaner: CleanerData[]
): string[] => {
  const cleanerIds = cleaner.map((cleaner) => cleaner.id)
  const queriedCleanerIds = queriedCleaner.map(
    (queriedCleaner) => queriedCleaner.id
  )

  const workerIds = new Set([...cleanerIds, ...queriedCleanerIds])

  return Array.from(workerIds)
}

export const transformWorkerVisits = (
  workerVisits: CleanerVisitsGroup
): VisitCellData[] => {
  return Object.values(workerVisits).reduce<VisitCellData[]>(
    (allVisits, visits) => {
      return [...allVisits, ...(visits || [])]
    },
    []
  )
}

export const transformWorkerTasks = (
  workerTasks: WorkerTasksGroup
): WorkerTaskCellData[] => {
  return Object.values(workerTasks).reduce<WorkerTaskCellData[]>(
    (allTasks, task) => {
      return [...allTasks, ...(task || [])]
    },
    []
  )
}

export const transformWorkerLeaves = (
  workerLeaves: CleanerLeavesGroup
): LeaveCellData[] => {
  return Object.values(workerLeaves).reduce<LeaveCellData[]>(
    (allLeaves, leaves) => {
      return [...allLeaves, ...(leaves || [])]
    },
    []
  )
}

export const filterCleanersNotInQueried = (
  cleaners: CleanerData[],
  queriedCleanerIds: string[]
): CleanerData[] => {
  const filteredCleaners = cleaners.filter(
    ({ id }) => !queriedCleanerIds.includes(id)
  )

  return filteredCleaners
}

export const generateOffdayCell = (
  restDays: WorkerRestDay[],
  dateRange: Date[]
): OffdayCellData[] => {
  return dateRange.reduce<OffdayCellData[]>((offDays, date) => {
    const day = getDay(date)
    const dateString = formatDate(date)
    const week = getWeekFromDateString(dateString, true)
    const currentWeekType =
      week % 2
        ? WorkerContractWeekTypeEnum.ODD
        : WorkerContractWeekTypeEnum.EVEN
    const matchingDays = restDays.filter((rest) => rest.day === day)
    matchingDays.forEach((restDay) => {
      if (restDay.weekType === WorkerContractWeekTypeEnum.ALL) {
        offDays.push({
          ownerId: [parseInt(restDay.workerId, 10)],
          startDate: `${dateString}T${restDay.startTime}`,
          endDate: `${dateString}T${restDay.endTime}`,
          offdayId: restDay.id,
          text: 'OFF DAY'
        })
      } else if (currentWeekType === restDay.weekType) {
        offDays.push({
          ownerId: [parseInt(restDay.workerId, 10)],
          startDate: `${dateString}T${restDay.startTime}`,
          endDate: `${dateString}T${restDay.endTime}`,
          offdayId: restDay.id,
          text: 'OFF DAY'
        })
      }
    })

    return offDays
  }, [])
}
