import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { Package } from 'ts/types/Package'
import { formatDate, formatTime24h } from 'utils/date'
import { workerName } from 'utils/helpers'

import { RootState } from '../../redux/slices'
import { ShortDay, shortDayIndex } from '../../ts/types/Days'
import { Task } from '../../ts/types/Tasks'
import {
  PackageDepartmentEnum,
  CreationReasonEnum,
  PackageTerminationReasonEnum,
  PackageActionsEnum,
  PackageServiceTypeEnum,
  PackageTerminationCategoryEnum
} from './../../__generated__/globalTypes'
import {
  PackageFrequencyGroupEnum,
  departmentMapper,
  temporaryFrequencyMapper
} from './form/lib'

export const normalScheduleWindow = {
  format: 'days',
  formatLabel: 'Day',
  maximumStart: 30
}

type ScheduleAction = {
  payload: Partial<Schedule>
}

type TasksAction = {
  tasks: Task[]
}

export interface ServiceTime {
  startTime: string
  endTime: string | null
}

export interface Schedule {
  startDate: string
  endDate: string | null
}

export interface ScheduleOutsideWindowModalAttributes {
  isConfirmed: boolean
}

type BookingSuspendedModalAttributes = {
  title: string | null
  body: string | null
}

type TerminatePackageData = {
  endDate: string
  packageCategory: PackageTerminationCategoryEnum | null
  reason?: PackageTerminationReasonEnum | null
  comment?: string | null
}

type SetPackageData = { packageData: Package }

type State = {
  newPackageId: null | string
  packageData: Package | null

  creationReason: CreationReasonEnum
  department: null | PackageDepartmentEnum
  contractId: string | null
  frequency: null | PackageFrequencyGroupEnum

  schedule: Schedule
  sessionScheduleDay: ShortDay[]
  sessionScheduleDate: ServiceTime[]
  tasks: Task[]

  isPreview: boolean

  packageModal: boolean
  cleanupCustomTime: boolean
  bookingSuspendedModal: boolean
  bookingSuspendedModalAttributes: BookingSuspendedModalAttributes
  scheduleOutsideWindowModal: boolean
  scheduleOutsideWindowModalAttributes: ScheduleOutsideWindowModalAttributes
  creationReasonModal: boolean
  departmentModal: boolean
  frequencyModal: boolean
  chooseContractModal: boolean
  deletePackageModal: boolean
  changePackageModal: boolean
  frequencyMismatch: {
    hasMismatch: boolean
    mismatchedFrequency: PackageFrequencyGroupEnum | null
  }
  terminatePackageModal: TerminatePackageData | null

  isCreatingRentalPackage: boolean
}

const initialStateSuspendBookingAttributes: BookingSuspendedModalAttributes = {
  title: null,
  body: null
}

const initialScheduleOutsideWindowModalAttributes: ScheduleOutsideWindowModalAttributes =
  {
    isConfirmed: true
  }

export const initialState: State = {
  newPackageId: null,
  creationReason: CreationReasonEnum.NEW_SALE,
  department: null,
  contractId: null,
  frequency: null,
  packageModal: false,
  packageData: null,
  bookingSuspendedModal: false,
  bookingSuspendedModalAttributes: initialStateSuspendBookingAttributes,
  scheduleOutsideWindowModal: false,
  scheduleOutsideWindowModalAttributes:
    initialScheduleOutsideWindowModalAttributes,
  schedule: {
    startDate: formatDate(new Date()),
    endDate: null
  },
  sessionScheduleDay: [],
  sessionScheduleDate: [],
  tasks: [],
  isPreview: true,
  creationReasonModal: false,
  departmentModal: false,
  frequencyModal: false,
  chooseContractModal: false,
  deletePackageModal: false,
  changePackageModal: false,
  terminatePackageModal: null,
  cleanupCustomTime: false,
  frequencyMismatch: {
    hasMismatch: false,
    mismatchedFrequency: null
  },

  isCreatingRentalPackage: false
}

export const setPackageDataAction = createAction<SetPackageData>(
  'package/setPackageData'
)

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    startCreatepackageModal(state) {
      state.newPackageId = ''
      state.creationReasonModal = true
    },
    setNewPackageId(
      state,
      { payload: { packageId } }: { payload: { packageId: string } }
    ): void {
      state.newPackageId = packageId // valid value: '', '123', null
      state.isPreview = false
    },
    setPackageData(state, action: PayloadAction<SetPackageData>): void {
      const { packageData } = action.payload
      state.frequency = temporaryFrequencyMapper(packageData.repeatEvery)
      state.department = packageData.department
      state.packageData = packageData

      // init session schedule
      const { tasks } = packageData
      const scheduleDate: ServiceTime[] = []
      const scheduleDay: ShortDay[] = []
      const days = shortDayIndex()

      tasks!.forEach((task) => {
        const { day, startTime, endTime } = task
        scheduleDay[day] = days[day]
        scheduleDate[day] = {
          startTime,
          endTime
        }
      })

      state.tasks = tasks!
      state.sessionScheduleDay = scheduleDay
      state.sessionScheduleDate = scheduleDate

      state.schedule = {
        startDate: packageData.startDate,
        endDate: packageData.endDate
      }

      state.isPreview = true

      // This will set modal into default package modal while creating rental package
      state.isCreatingRentalPackage = false

      // Notes: this reducer will affect jobListSlice and lineItemSlice from:
      // setPackageDataAction
    },
    showPackageModal(state): void {
      state.packageModal = true
    },
    cleanupPackageModal(state): void {
      state.packageModal = false
      state.newPackageId = null
      state.packageData = null
      state.cleanupCustomTime = true

      state.schedule = {
        startDate: formatDate(new Date()),
        endDate: null
      }
      state.sessionScheduleDay = []
      state.sessionScheduleDate = []
      state.tasks = []

      state.creationReason = CreationReasonEnum.NEW_SALE
      state.department = null
      state.frequency = null
      state.creationReasonModal = false
      state.departmentModal = false
      state.chooseContractModal = false
      state.frequencyModal = false
      state.isCreatingRentalPackage = false
    },

    setNewTasks(state, action: PayloadAction<TasksAction>): void {
      state.tasks = action.payload.tasks
    },
    setSchedule(state, action: ScheduleAction): void {
      const { startDate, endDate } = action.payload
      if (startDate) {
        state.schedule = { ...state.schedule, startDate }
      }
      if (endDate) {
        state.schedule = { ...state.schedule, endDate }
      }
    },
    initSessionScheduleFromTasks(state, action: PayloadAction<TasksAction>) {
      const { tasks } = action.payload
      const scheduleDate: ServiceTime[] = []
      const scheduleDay: ShortDay[] = []
      const days = shortDayIndex()

      tasks.forEach((task) => {
        const { day, startTime, endTime } = task
        scheduleDay[day] = days[day]
        scheduleDate[day] = {
          startTime,
          endTime
        }
      })

      state.tasks = tasks
      state.sessionScheduleDay = scheduleDay
      state.sessionScheduleDate = scheduleDate
    },
    setSessionSchedule(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ): void {
      const { name, value } = action.payload
      const days = shortDayIndex()

      const [, timeLabel] = name.split('.')
      const timeIndex = timeLabel as keyof ServiceTime
      const [dayIndex] = /\d/.exec(name)!
      const index = Number(dayIndex)

      const scheduleDate = state.sessionScheduleDate[index] ?? {}
      scheduleDate[timeIndex] = value

      if (!scheduleDate.startTime && !scheduleDate.endTime) {
        delete state.sessionScheduleDay[index]
        delete state.sessionScheduleDate[index]
      } else {
        state.sessionScheduleDay[index] = days[index]
        state.sessionScheduleDate[index] = scheduleDate
      }
    },

    // MODAL SECTION
    // Package Creation
    showCreationReasonModal(state) {
      state.creationReasonModal = true
    },
    hideCreationReasonModal(state) {
      state.creationReasonModal = false
    },
    setCreationReason(state, action: PayloadAction<CreationReasonEnum>) {
      state.creationReason = action.payload
    },
    // Department
    showDepartmentModal(state) {
      state.departmentModal = true
    },
    hideDepartmentModal(state) {
      state.departmentModal = false
    },
    setDepartment(state, action: PayloadAction<PackageDepartmentEnum>) {
      state.department = action.payload
    },
    // Frequency
    showFrequencyModal(state) {
      state.frequencyModal = true
    },
    hideFrequencyModal(state) {
      state.frequencyModal = false
    },
    setFrequency(state, action: PayloadAction<PackageFrequencyGroupEnum>) {
      state.frequency = action.payload
    },
    // ContractId
    showChooseContractModal(state) {
      state.chooseContractModal = true
    },
    hideChooseContractModal(state) {
      state.chooseContractModal = false
    },
    setContractId(state, action: PayloadAction<{ contractId: string | null }>) {
      state.contractId = action.payload.contractId
    },
    // Delete package
    showDeletePackageModal(state) {
      state.deletePackageModal = true
    },
    hideDeletePackageModal(state) {
      state.deletePackageModal = false
    },
    // Update package
    showChangePackageModal(state) {
      state.changePackageModal = true
    },
    hideChangePackageModal(state) {
      state.changePackageModal = false
    },
    // Terminate package
    showTerminatePackageModal(state) {
      const packageData = state.packageData
      if (packageData) {
        const visitsMaxDate =
          packageData.lastCompletedVisit?.serviceDate ?? packageData.endDate
        state.terminatePackageModal = {
          endDate: packageData.endDate ?? visitsMaxDate,
          reason: packageData.terminationReason,
          comment: packageData.terminationComment,
          packageCategory: packageData.terminationCategory
        }
      }
    },
    hideTerminatePackageModal(state) {
      state.terminatePackageModal = null
    },
    // Booking suspend
    showBookingSuspendedModal(
      state,
      action: PayloadAction<BookingSuspendedModalAttributes>
    ): void {
      state.bookingSuspendedModal = true
      state.bookingSuspendedModalAttributes = action.payload
    },
    hideBookingSuspendedModal(state): void {
      state.bookingSuspendedModal = false
      state.bookingSuspendedModalAttributes =
        initialStateSuspendBookingAttributes
    },
    setPreview(state, action: PayloadAction<boolean>) {
      state.isPreview = action.payload
    },
    createRentalPackage(state) {
      state.isCreatingRentalPackage = true
    },
    showScheduleOutsideWindowModal(state) {
      state.scheduleOutsideWindowModal = true
    },
    hideScheduleOutsideWindowModal(state) {
      state.scheduleOutsideWindowModal = false
    },
    setScheduleOutsideWindowModalAttributes(
      state,
      action: PayloadAction<ScheduleOutsideWindowModalAttributes>
    ) {
      state.scheduleOutsideWindowModalAttributes = action.payload
    },
    setFrequencyMismatch(
      state,
      action: PayloadAction<{
        hasMismatch: boolean
        mismatchedFrequency: PackageFrequencyGroupEnum | null
      }>
    ) {
      state.frequencyMismatch = action.payload
    }
  }
})

export const packageModalSelector = (state: RootState): boolean =>
  state.packageReducer.packageModal

export const packageDataSelector = (state: RootState) =>
  state.packageReducer.packageData

export const bookingSuspendedModalSelector = (state: RootState): boolean =>
  state.packageReducer.bookingSuspendedModal

export const bookingSuspendedModalAttributesSelector = (
  state: RootState
): BookingSuspendedModalAttributes =>
  state.packageReducer.bookingSuspendedModalAttributes

export const scheduleOutsideWindowModalSelector = (state: RootState): boolean =>
  state.packageReducer.scheduleOutsideWindowModal

export const scheduleOutsideWindowModalAttributesSelector = (
  state: RootState
): ScheduleOutsideWindowModalAttributes =>
  state.packageReducer.scheduleOutsideWindowModalAttributes

export const newPackageIdSelector = (state: RootState): string | null =>
  state.packageReducer.newPackageId

export const departmentSelector = (
  state: RootState
): PackageDepartmentEnum | null => state.packageReducer.department

export const frequencySelector = (
  state: RootState
): PackageFrequencyGroupEnum | null => state.packageReducer.frequency

export const contractIdSelector = (state: RootState): string | null =>
  state.packageReducer.contractId

export const departmentLabelSelector = (state: RootState): string => {
  // TODO: enhance this later for rental package
  if (
    state.packageReducer.isCreatingRentalPackage ||
    state.packageReducer.packageData?.serviceType ===
      PackageServiceTypeEnum.EQUIPMENT
  ) {
    return 'Rental Equipment Recurring'
  }

  if (state.packageReducer.department && state.packageReducer.frequency) {
    return `${departmentMapper(state.packageReducer.department)} ${
      state.packageReducer.frequency
    }`
  }
  return ''
}

export const scheduleSelector = (state: RootState): Schedule =>
  state.packageReducer.schedule

export const sessionScheduleDaysSelector = (state: RootState): ShortDay[] =>
  state.packageReducer.sessionScheduleDay

export const sessionScheduleDatesSelector = (state: RootState): ServiceTime[] =>
  state.packageReducer.sessionScheduleDate

export const tasksSelector = (state: RootState): Task[] =>
  state.packageReducer.tasks

export const packageStatusSelector = createSelector(
  packageDataSelector,
  (packageData) => packageData?.status
)

export const creationReasonSelector = (state: RootState): CreationReasonEnum =>
  state.packageReducer.creationReason

export const creationReasonModalSelector = (state: RootState) =>
  state.packageReducer.creationReasonModal

export const departmentModalSelector = (state: RootState) =>
  state.packageReducer.departmentModal

export const frequencyModalSelector = (state: RootState) =>
  state.packageReducer.frequencyModal

export const chooseContractModalSelector = (state: RootState) =>
  state.packageReducer.chooseContractModal

export const isPreviewPackageSelector = (state: RootState) =>
  state.packageReducer.isPreview

export const isCreatingRentalPackageSelector = (state: RootState) =>
  state.packageReducer.isCreatingRentalPackage

export const isReadyCreateNewPackageSelector = (state: RootState) =>
  !!state.packageReducer.creationReason &&
  !!state.packageReducer.department &&
  !!state.packageReducer.frequency &&
  !!state.packageReducer.contractId

export const isFromClientPageSelector = (state: RootState) =>
  !!state.packageReducer.creationReason &&
  !!state.packageReducer.department &&
  !state.packageReducer.frequency &&
  state.packageReducer.newPackageId === ''

export const isValidToDeletePackageSelector = createSelector(
  packageDataSelector,
  (packageData) =>
    !!packageData?.allowedActions?.includes(PackageActionsEnum.DELETE)
)

export const isHomeCleaningServiceHaveLineItemSelector = createSelector(
  (state: RootState) => state.lineItemReducer.lineItems,
  departmentSelector,
  (lineItems, department) =>
    lineItems.length === 1 &&
    !!department &&
    [
      PackageDepartmentEnum.HOME_CLEANING,
      PackageDepartmentEnum.OFFICE_CLEANING
    ].includes(department)
)

export const deletePackageModalSelector = (state: RootState) =>
  state.packageReducer.deletePackageModal

export const changePackageModalSelector = (state: RootState) =>
  state.packageReducer.changePackageModal

export const terminatePackageModalSelector = (state: RootState) =>
  state.packageReducer.terminatePackageModal

export const cleanupCustomTimeSelector = (state: RootState) =>
  state.packageReducer.cleanupCustomTime

export const airconPackageUpdateSelector = createSelector(
  packageDataSelector,
  tasksSelector,
  (packageData, [firstTask]) => {
    const workers = packageData!.jobs!.reduce<string[]>((workers, job) => {
      if (job.worker) {
        workers.push(workerName(job.worker))
      }

      return workers
    }, [])

    return {
      fullAddress: packageData?.fullAddress ?? '',
      postalCode: packageData?.postalCode ?? '',
      firstVisitDate: formatDate(
        new Date(packageData?.startDate),
        'dd/MM/yyyy'
      ),
      startDate: packageData?.startDate ?? '',
      startTime: formatTime24h(firstTask.startTime),
      endTime: formatTime24h(firstTask.endTime),
      workers
    }
  }
)

export const mismatchFrequencySelector = (state: RootState) =>
  state.packageReducer.frequencyMismatch

export const isRentalPackageSelector = (state: RootState) =>
  state.packageReducer.isCreatingRentalPackage ||
  state.packageReducer.packageData?.serviceType ===
    PackageServiceTypeEnum.EQUIPMENT

const { name, actions, reducer } = packageSlice

export { name, actions, reducer, actions as packageActions }
