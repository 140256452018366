import { TableCell, TableContainer, TableRow } from '@luce/ui-kit'
import BoxRow from '@luce/ui-kit/components/atom/BoxRow'
import Loader from '@luce/ui-kit/components/atom/CircularProgress'
import HeadTableSortLabel, {
  HeadTableCell,
  Order
} from '@luce/ui-kit/components/atom/HeadTableSortLabel'
import Link from '@luce/ui-kit/components/atom/Link'
import NoCountTablePagination, {
  NoCountTablePaginationProps
} from '@luce/ui-kit/components/atom/NoCountTablePagination'
import Color from '@luce/ui-kit/components/base/BaseColor'
import Box from '@luce/ui-kit/components/base/Box'
import Button from '@luce/ui-kit/components/button/Button'
import TextField, {
  MenuItem,
  SelectOptions
} from '@luce/ui-kit/components/input'
import TableList from '@luce/ui-kit/components/molecules/TableList'
import { makeStyles, Theme } from '@luce/ui-kit/themes/ThemeProvider'
import {
  NationalityEnum,
  WorkerDepartmentEnum,
  WorkerTipSortByEnum
} from '__generated__/globalTypes'
import React from 'react'
import { WorkerTipList } from 'ts/types/WorkerTips'

import WorkerTipFormModal from './form/WorkerTipFormModal.screen'
import { WorkerDepartmentMapping } from 'components/cleaner/CleanerValues'

export type WorkerTipsValues = {
  id: string
  title: string
  message: string
  department: WorkerDepartmentEnum
  nationality: NationalityEnum
}

export type FormStatus = { success?: boolean; error?: string }

interface Props {
  pagination: NoCountTablePaginationProps
  order: Order
  orderBy: WorkerTipSortByEnum
  handleRequestSort(property: string): void
  loading: boolean
  workerTipsValues: WorkerTipsValues[]
  nationalityOptions: SelectOptions
  workerTipModal: boolean
  handleShowModal(): void
  search: string
  handleSearch(value: string): void
  onCloseModal(): void
  refetchWorkerTip(): void
  handleOpenDetails(data: WorkerTipList | null): void
  workerTip: WorkerTipList | null
  onChangeNationality(event: React.ChangeEvent): void
  nationality: NationalityEnum | string
}

const useStyles = makeStyles((theme: Theme) => ({
  bodyRow: {
    '& .MuiTableCell-body': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      color: theme.palette.grey[800],
      ...theme.typography.subtitle2
    }
  },
  searchInput: {
    width: 454,
    '& .MuiOutlinedInput-root': {
      background: Color.primaryColor[300],
      borderRadius: 4
    }
  },
  selectStatus: {
    width: 254
  },
  buttonAdd: {
    width: 175
  },
  message: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  linkStyle: {
    color: theme.palette.info.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

const WorkerTipScreen: React.FC<Props> = (props) => {
  const classes = useStyles()
  const {
    pagination,
    order,
    orderBy,
    handleRequestSort,
    loading,
    workerTipsValues,
    nationalityOptions,
    workerTipModal,
    handleShowModal,
    onCloseModal,
    refetchWorkerTip,
    handleOpenDetails,
    onChangeNationality,
    workerTip,
    nationality,
    search,
    handleSearch
  } = props
  
  const HeadCellTable: HeadTableCell[] = [
    {
      valueName: '',
      isNotSortAble: true,
      label: 'Message Title'
    },
    {
      valueName: '',
      isNotSortAble: true,
      label: 'Message Body'
    },
    {
      valueName: WorkerTipSortByEnum.department,
      label: 'Department'
    },
    {
      valueName: WorkerTipSortByEnum.nationality,
      label: 'Nationality'
    },
    {
      valueName: '',
      isNotSortAble: true,
      label: 'Action'
    }
  ]

  return (
    <Box bgcolor="common.white" py={6} pb={3} px={3}>
      <BoxRow align="flex-end" mb={4} justifyContent="flex-end">
        <BoxRow flex={1} gap={2}>
          <TextField
            className={classes.searchInput}
            customSize="large"
            icon="SearchIcon"
            label="&nbsp;"
            placeholder="Search Message by Title"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <TextField
            className={classes.selectStatus}
            customSize="large"
            select
            label="Filter Messages by Nationality"
            onChange={onChangeNationality}
            value={nationality}
          >
            {nationalityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </BoxRow>
        <Button
          className={classes.buttonAdd}
          variants="primary"
          customSize="large"
          onClick={handleShowModal}
        >
          Add a new message
        </Button>
      </BoxRow>
      <TableContainer>
        <TableList
          head={
            <HeadTableSortLabel
              headCells={HeadCellTable}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
          }
          body={
            <>
              {!!workerTipsValues.length &&
                workerTipsValues.map((value) => (
                  <TableRow key={value.id}>
                    <TableCell>{value.title}</TableCell>
                    <TableCell className={classes.message}>
                      {value.message}
                    </TableCell>
                    <TableCell>{WorkerDepartmentMapping[value.department]}</TableCell>
                    <TableCell>{value.nationality}</TableCell>
                    <TableCell align="left">
                      <Link
                        onClick={() => handleOpenDetails(value)}
                        className={classes.linkStyle}
                      >
                        View Detail
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              {!workerTipsValues.length && !loading && (
                <TableRow className={classes.bodyRow}>
                  <TableCell colSpan={11} align="center">
                    Sorry, No result found :(, please try again!
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={11} align="center" variant="head">
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
            </>
          }
          footer={
            <TableRow>
              <NoCountTablePagination
                {...pagination}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </TableRow>
          }
        />
      </TableContainer>
      <WorkerTipFormModal
        refetchWorkerTip={refetchWorkerTip}
        workerTip={workerTip}
        workerTipModal={workerTipModal}
        onCloseModal={onCloseModal}
        nationalityOptions={nationalityOptions}
      />
    </Box>
  )
}

export default WorkerTipScreen
