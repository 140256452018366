import { LineItem } from 'components/lineItem/lib'
import { Client } from 'ts/types/Client'
import { compareTime, formatDate } from 'utils/date'
import { setUnitNumber } from 'utils/helpers'
import * as Yup from 'yup'

import {
  PackageRecurrenceEnum,
  PackageDepartmentEnum,
  createAdhocPackageInput,
  PackageDetailBillingUnitEnum,
  BillingTypeEnum,
  createPackageInput,
  PackageLineItemInput,
  TaskInput,
  PackageServiceTypeEnum
} from '../../../__generated__/globalTypes'
import {
  Package,
  ServiceType,
  ServiceFrequency
} from '../../../ts/types/Package'
import { Schedule } from '../package.slice'

const INTIAL_START_TIME = '08:00'
const INITIAL_END_TIME = '17:00'

export interface FormValues {
  department: PackageDepartmentEnum | null
  frequency: PackageFrequencyGroupEnum | null
  serviceBillingValue: string
  addressId: string
  postalCode: string
  fullAddress: string
  unitNumber: string | null
  unitFloor?: string
  apartmentNumber?: string
  accessType: string
  additionalNotes: string
  startDate: string
  endDate: string | null
  startTime: string | null
  endTime: string | null
  customTime: CustomTime[]
  workerId?: string | null
  workerContractId?: string | null
  schedule?: ScheduleTime[]
}

export type ScheduleTime = {
  day: string
  workingHours: ScheduleCustomTime[]
}

export type ScheduleCustomTime = { startTime: string; endTime: string }

export enum PackageFrequencyGroupEnum {
  RECURRING_FIXED = 'Recurring Fixed',
  AD_HOC = 'Ad hoc',
  RECURRING = 'Recurring',
  PACKAGE = 'Package'
}

export type CustomTime = { startTime: string | null; endTime: string | null }

export interface AdhocHomeCleaningInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.HOURLY
  department: PackageDepartmentEnum.HOME_CLEANING
  billingType: BillingTypeEnum.POSTPAID
  packageLineItems: [PackageLineItemInput]
  startTime: string
  endTime: string
}
export interface AdhocAirconInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.AIRCON
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface AdhocOfficeCleaningInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit:
    | PackageDetailBillingUnitEnum.HOURLY
    | PackageDetailBillingUnitEnum.FIXED
    | PackageDetailBillingUnitEnum.PER_SQFT
  department: PackageDepartmentEnum.OFFICE_CLEANING
  billingType: BillingTypeEnum.POSTPAID
  packageLineItems: [PackageLineItemInput]
  startTime: string
  endTime: string
}

export interface AdhocCarWashInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.CAR_WASH
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface AdhocBabySitterInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.BABYSITTER
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface RecurringBabysitterInput
  extends Pick<
    createPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'startDate'
    | 'endDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit:PackageDetailBillingUnitEnum
  department: PackageDepartmentEnum.BABYSITTER
  billingType: BillingTypeEnum.POSTPAID
  packageLineItems: [PackageLineItemInput]
  tasks: Required<Omit<TaskInput, 'startTime' | 'endTime'>>[]
}

export interface AdhocElderCareInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.ELDER_CARE
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface RecurringElderCareInput
  extends Pick<
    createPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'startDate'
    | 'endDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit:PackageDetailBillingUnitEnum
  department: PackageDepartmentEnum.ELDER_CARE
  billingType: BillingTypeEnum.POSTPAID
  packageLineItems: [PackageLineItemInput]
  tasks: Required<Omit<TaskInput, 'startTime' | 'endTime'>>[]
}

export interface RecurringHomeCleaningInput
  extends Pick<
    createPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'startDate'
    | 'endDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.HOURLY
  department: PackageDepartmentEnum.HOME_CLEANING
  billingType: BillingTypeEnum.POSTPAID
  packageLineItems: [PackageLineItemInput]
  tasks: Required<Omit<TaskInput, 'startTime' | 'endTime'>>[]
}

export interface PackageAirconInput
  extends Pick<
    createPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'startDate'
    | 'endTime'
    | 'startTime'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'firstVisitLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.AIRCON
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface RecurringOfficeCleaningInput
  extends Pick<
    createPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'startDate'
    | 'endDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit:
    | PackageDetailBillingUnitEnum.HOURLY
    | PackageDetailBillingUnitEnum.FIXED
    | PackageDetailBillingUnitEnum.PER_SQFT
  department: PackageDepartmentEnum.OFFICE_CLEANING
  billingType: BillingTypeEnum.POSTPAID
  packageLineItems: [PackageLineItemInput]
  tasks: Required<Omit<TaskInput, 'startTime' | 'endTime'>>[]
}

export interface AdhocCarpetUpholsteryInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit:
    | PackageDetailBillingUnitEnum.HOURLY
    | PackageDetailBillingUnitEnum.FIXED
    | PackageDetailBillingUnitEnum.PER_SQFT
  department: PackageDepartmentEnum.CARPET_UPHOLSTERY
  billingType: BillingTypeEnum.PREPAID
  packageLineItems: PackageLineItemInput[]
  startTime: string
  endTime: string
}

export interface AdhocHomeBeautyInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.HOME_BEAUTY
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface AdhocHandymanInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.HANDYMAN
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export interface AdhocMassageInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.MASSAGE
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}


export interface AdhocPetCareInput
  extends Pick<
    createAdhocPackageInput,
    | 'accessType'
    | 'additionalNotes'
    | 'fullAddress'
    | 'postalCode'
    | 'unitNumber'
    | 'contractId'
    | 'serviceDate'
    | 'creationReason'
    | 'workerId'
    | 'workerContractId'
    | 'packageLineItems'
    | 'promoCode'
    | 'skipOverdueInvoiceCheck'
  > {
  billingUnit: PackageDetailBillingUnitEnum.FIXED
  department: PackageDepartmentEnum.PET_CARE
  billingType: BillingTypeEnum.PREPAID
  startTime: string
  endTime: string
}

export const initialCustomTimeArray: CustomTime[] = [
  { startTime: null, endTime: null },
  { startTime: null, endTime: null },
  { startTime: null, endTime: null },
  { startTime: null, endTime: null },
  { startTime: null, endTime: null },
  { startTime: null, endTime: null },
  { startTime: null, endTime: null }
]

// for officeclean
export const DEFAULT_TIME = '08:00'
export const SESSION_TIME_DEFAULT = '18:00'
export const END_TIME_DEFAULT = '23:00'

const initialSchedule = (): Schedule => {
  return {
    startDate: formatDate(new Date()),
    endDate: null
  }
}

const initialStartDate = (packageData: Package | null): string => {
  if (!packageData) {
    return initialSchedule().startDate
  }

  return packageData.startDate
}

const initialEndDate = (packageData: Package | null): string | null => {
  if (!packageData) {
    return initialSchedule().endDate ?? null
  }
  return packageData.endDate
}

const initialStartTime = (packageData: Package | null): string => {
  if (!packageData) {
    return DEFAULT_TIME
  }

  const [task] = packageData.tasks ?? []
  return task ? task.startTime : DEFAULT_TIME
}

export const packageFrequencyGroupMapper = (
  frequency?: PackageFrequencyGroupEnum | null
): PackageRecurrenceEnum[] | undefined => {
  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
      return [PackageRecurrenceEnum.AD_HOC]
    case PackageFrequencyGroupEnum.RECURRING:
      return [
        PackageRecurrenceEnum.FIXED_DW_HALF_YEAR,
        PackageRecurrenceEnum.FIXED_DW_MONTH,
        PackageRecurrenceEnum.FIXED_DW_QUARTER,
        PackageRecurrenceEnum.DAY,
        PackageRecurrenceEnum.FORTNIGHT,
        PackageRecurrenceEnum.WEEK,
        PackageRecurrenceEnum.MONTH,
        PackageRecurrenceEnum.YEAR
      ]
    case PackageFrequencyGroupEnum.PACKAGE:
      return [PackageRecurrenceEnum.YEAR]
    default:
      console.warn(`unhandled packageFrequencyGroup: ${frequency}`)
      return undefined
  }
}

export const serviceFrequencyMapper = (
  repeatEvery: PackageRecurrenceEnum,
  times?: number
): ServiceFrequency | undefined => {
  switch (repeatEvery) {
    case PackageRecurrenceEnum.FIXED_DW_MONTH:
      return 'Fixed Monthly'
    case PackageRecurrenceEnum.FIXED_DW_QUARTER:
      return 'Fixed Quarterly'
    case PackageRecurrenceEnum.FIXED_DW_HALF_YEAR:
      return 'Fixed Biyearly'
    case PackageRecurrenceEnum.AD_HOC:
      return 'Ad Hoc'
    case PackageRecurrenceEnum.WEEK:
      return 'Weekly'
    case PackageRecurrenceEnum.FORTNIGHT:
      return 'Fortnightly'
    case PackageRecurrenceEnum.MONTH:
      return 'Monthly'
    case PackageRecurrenceEnum.YEAR:
      switch (times) {
        case 2:
          return 'Biyearly'
        case 3:
          return 'Tri-yearly'
        case 4:
          return 'Quarterly'
        default:
          return 'Yearly'
      }
    default:
      console.warn(`unhandled repeatEvery: ${repeatEvery}`)
      return undefined
  }
}

const departmentMapping = {
  [PackageDepartmentEnum.HOME_CLEANING]: 'Home Cleaning',
  [PackageDepartmentEnum.OFFICE_CLEANING]: 'Office Cleaning',
  [PackageDepartmentEnum.AIRCON]: 'Aircon',
  [PackageDepartmentEnum.CARPET_UPHOLSTERY]: 'Carpet Upholstery',
  [PackageDepartmentEnum.HOME_BEAUTY]: 'Home Beauty',
  [PackageDepartmentEnum.HANDYMAN]: 'Handyman',
  [PackageDepartmentEnum.CAR_WASH]: 'Car Wash',
  [PackageDepartmentEnum.MASSAGE]: 'Massage',
  [PackageDepartmentEnum.BABYSITTER]: 'Baby Sitting',
  [PackageDepartmentEnum.PET_CARE]: 'Pet Care',
  [PackageDepartmentEnum.ELDER_CARE]: 'Elder Care',
  [PackageDepartmentEnum.DEEP_CLEANING]: 'Deep Cleaning'
}

export const departmentMapper = (
  department?: PackageDepartmentEnum
): ServiceType => {
  if(!department) {
    console.warn(`unhandled department: ${department}`)
    return 'Home Cleaning'
  }

  const departmentLabel = departmentMapping[department]
  return departmentLabel as ServiceType
}

export const temporaryFrequencyMapper = (
  repeatEvery?: PackageRecurrenceEnum
): PackageFrequencyGroupEnum | null => {
  switch (repeatEvery) {
    case PackageRecurrenceEnum.AD_HOC:
      return PackageFrequencyGroupEnum.AD_HOC
    case PackageRecurrenceEnum.FIXED_DW_MONTH:
    case PackageRecurrenceEnum.FIXED_DW_QUARTER:
    case PackageRecurrenceEnum.FIXED_DW_HALF_YEAR:
      return PackageFrequencyGroupEnum.RECURRING_FIXED
    case PackageRecurrenceEnum.DAY:
    case PackageRecurrenceEnum.WEEK:
    case PackageRecurrenceEnum.FORTNIGHT:
    case PackageRecurrenceEnum.MONTH:
    case PackageRecurrenceEnum.YEAR:
      return PackageFrequencyGroupEnum.RECURRING
    default:
      console.warn(`unhandled repeatEvery: ${repeatEvery}`)
      return null
  }
}

export function formatPackageService(
  repeatEvery: PackageRecurrenceEnum,
  department: PackageDepartmentEnum,
  serviceType: PackageServiceTypeEnum,
  repeatEveryTimes?: number
): string {
  // TODO: will enhance this later for rental package
  if (serviceType === PackageServiceTypeEnum.EQUIPMENT) {
    return `${serviceFrequencyMapper(
      repeatEvery,
      repeatEveryTimes
    )} Rental Equipment`
  }

  return `${serviceFrequencyMapper(
    repeatEvery,
    repeatEveryTimes
  )} ${departmentMapper(department)}`
}

const initialEndTime = (packageData: Package | null): string | null => {
  if (!packageData) {
    return null
  }

  if (packageData.repeatEvery === PackageRecurrenceEnum.AD_HOC) {
    const [task] = packageData.tasks!
    return task.endTime
  }

  return DEFAULT_TIME
}

export const initialCustomTime = (
  packageData: Package | null
): CustomTime[] => {
  const defaultData: CustomTime[] = initialCustomTimeArray

  if (!packageData) {
    return defaultData
  }

  const { tasks } = packageData
  if (tasks) {
    tasks.forEach((task) => {
      const { day, startTime, endTime } = task
      defaultData[day] = {
        startTime,
        endTime
      }
    })
  }

  return defaultData
}

const initialScheduleData = (packageData: Package | null): ScheduleTime[] => {
  const schedule = [
    {
      day: 'Mo',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    },
    {
      day: 'Tu',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    },
    {
      day: 'We',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    },
    {
      day: 'Th',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    },
    {
      day: 'Fr',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    },
    {
      day: 'Sa',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    },
    {
      day: 'Su',
      workingHours: [
        { startTime: INTIAL_START_TIME, endTime: INITIAL_END_TIME }
      ]
    }
  ]

  if (packageData?.tasks) {
    packageData.tasks!.forEach((task) => {
      schedule[task.day].workingHours = [
        { startTime: task.startTime, endTime: task.endTime }
      ]
    })

    const taskDays = packageData.tasks.map((task) => task.day)
    schedule.forEach((item, index) => {
      if (taskDays.includes(index)) {
        return
      }

      item.workingHours = [{ startTime: '', endTime: '' }]
    })
  }

  return schedule
}

export function initialValues(
  department: PackageDepartmentEnum | null,
  frequency: PackageFrequencyGroupEnum | null,
  packageData: Package | null,
  currentClient: Client | null
): FormValues {
  const [firstAddress] = currentClient?.addresses ?? []
  let address = {
    fullAddress: '',
    postalCode: '',
    unitNumber: ''
  }

  if (packageData) {
    address = {
      fullAddress: packageData.fullAddress,
      postalCode: packageData.postalCode,
      unitNumber: packageData.unitNumber ?? ''
    }
  } else if (firstAddress) {
    address = {
      fullAddress: firstAddress.fullAddress,
      postalCode: firstAddress.postalCode,
      unitNumber: firstAddress.unitNumber ?? ''
    }
  }

  return {
    frequency,
    department,
    serviceBillingValue: packageData?.serviceBillingValue
      ? `$${packageData.serviceBillingValue.toFixed(2)}`
      : '$0',
    addressId: packageData?.fullAddress ? 'off-address' : firstAddress.id,
    fullAddress: address.fullAddress,
    unitNumber: address.unitNumber,
    apartmentNumber: setUnitNumber('apartmentNumber', address.unitNumber),
    unitFloor: setUnitNumber('unitFloor', address.unitNumber),
    postalCode: address.postalCode,
    accessType: packageData?.accessType ?? '',
    additionalNotes: packageData?.additionalNotes ?? '',
    startDate: initialStartDate(packageData),
    endDate: initialEndDate(packageData),
    startTime: initialStartTime(packageData),
    endTime: initialEndTime(packageData),
    customTime: initialCustomTime(packageData), // TODO: cleanup later
    schedule: initialScheduleData(packageData)
  }
}

export const recurringPackages = [
  PackageDepartmentEnum.HOME_CLEANING,
  PackageDepartmentEnum.OFFICE_CLEANING,
  PackageDepartmentEnum.BABYSITTER,
  PackageDepartmentEnum.ELDER_CARE
]

export const FormSchema = Yup.object().shape(
  {
    frequency: Yup.string(),
    department: Yup.string(),
    fullAddress: Yup.string().required('Address is a required field'),
    postalCode: Yup.string().required('Postal Code is a required field'),
    unitNumber: Yup.string(),
    accessType: Yup.string().nullable(),
    additionalNotes: Yup.string().nullable(),

    startDate: Yup.string().when(['department', 'frequency'], {
      is: (department, frequency) =>
        recurringPackages.includes(department) &&
        frequency === PackageFrequencyGroupEnum.RECURRING,
      then: () => Yup.string().required('Start date is a required field'),
      otherwise: () => Yup.string().required('Service Date is a required field')
    }),
    endDate: Yup.string().nullable(),
    startTime: Yup.string().when(['department', 'frequency'], {
      is: (department, frequency) =>
        recurringPackages.includes(department) &&
        frequency === PackageFrequencyGroupEnum.RECURRING,
      then: () => Yup.string(),
      otherwise: () =>
        Yup.string()
          .required('Start time is required')
          .typeError('Start time is required')
    }),
    endTime: Yup.string().when(['department', 'frequency'], {
      is: (department, frequency) =>
        recurringPackages.includes(department) &&
        frequency === PackageFrequencyGroupEnum.RECURRING,
      then: () => Yup.string(),
      otherwise: () =>
        Yup.string()
          .required('End time is required')
          .typeError('End time is required')
    }),
    customTime: Yup.array().of(
      Yup.object().shape({
        startTime: Yup.string().nullable(),
        endTime: Yup.string().nullable()
      })
    ),
    workerId: Yup.string().nullable(),
    workerContractId: Yup.string().nullable()
  },
  [['department', 'frequency']]
)

export const isSessionScheduleValid = (
  packageCode: string,
  timeData: CustomTime[] | ScheduleTime[]
) => {
  return timeData.map((time) => {
    const startTime = 'startTime' in time ? time.startTime : time.workingHours[0]?.startTime;
    
    if (startTime) {
      if (
        (packageCode.includes('E') &&
          compareTime(startTime, SESSION_TIME_DEFAULT) === 1) ||
        compareTime(startTime, END_TIME_DEFAULT) < 0
      ) {
        return 'not-valid-evening';
      } else if (
        (packageCode.includes('D') &&
          compareTime(startTime, SESSION_TIME_DEFAULT) <= 0) ||
        compareTime(startTime, DEFAULT_TIME) === 1
      ) {
        return 'not-valid-day';
      }
    }
    return ''
  });
};


export const transformLineItemToInput = (
  lineItem: LineItem
): PackageLineItemInput => {
  const packageLineItem: PackageLineItemInput = {
    packageDetailId: lineItem.packageDetail.id,
    packageDescription: lineItem.description,
    billingUnit: lineItem.packageDetail.billingUnit,
    unitValue: lineItem.unitValue,
    units: lineItem.unitsNumber
  }

  return packageLineItem
}

export const mapLineItemToInput = (
  lineItems: LineItem[]
): PackageLineItemInput[] => {
  return lineItems.map(transformLineItemToInput)
}
